import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BaseModule } from '@cosCoreComponentsGeneral/base.module';
import { InstantPurchaseButtonComponent } from './instant-purchase-button.component';
import { I18nAuctionPipesModule } from '@caronsale/frontend-pipes';
import { FeeInfoModule } from '@cosBuyer/partials/fee-info/fee-info.module';
import { CrossBorderDiscountBadgeModule } from '../../cross-border-discount-badge/cross-border-discount-badge.module';
import { EnzoComponentsModule } from '@enzo';
import { AuctionVatModule } from '../auction-vat/auction-vat.module';

@NgModule({
  declarations: [InstantPurchaseButtonComponent],
  exports: [InstantPurchaseButtonComponent],
  imports: [
    //
    AuctionVatModule,
    CommonModule,
    BaseModule,
    I18nAuctionPipesModule,
    FeeInfoModule,
    CrossBorderDiscountBadgeModule,
    EnzoComponentsModule,
  ],
})
export class InstantPurchaseButtonModule {}
