import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { IBuyerAuctionView, Validation } from '@caronsale/cos-models';
import { Popover } from '@cosCoreFeatures/@cos/cos-popover/services/popover.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-highest-bid-value',
  templateUrl: './highest-bid-value.component.html',
  styleUrls: ['./highest-bid-value.component.scss'],
})
export class HighestBidValueComponent implements OnChanges {
  @Input()
  public auction: IBuyerAuctionView;

  @Input()
  public showText: boolean = true;

  @Input()
  public textAlignment: 'horizontal' | 'vertical' = 'vertical';

  @Input()
  public bidValueSize: 'normal' | 'medium' | 'small' | 'tiny' = 'normal';

  @Input()
  public hideBidStatusIcon: boolean = false;

  public animatePrice = false;
  public isStartPrice = false;

  public constructor(
    //
    private popover: Popover,
    private translateService: TranslateService,
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (!changes.auction) {
      return;
    }

    const previousHighestBidValue: number = changes.auction.previousValue?.currentHighestBidValue;
    const currentHighestBidValue: number = changes.auction.currentValue.currentHighestBidValue;

    this.isStartPrice = this.isStartingBidValue(currentHighestBidValue);

    if (changes.auction.previousValue?.currentHighestBidValue !== changes.auction.currentValue?.currentHighestBidValue) {
      requestAnimationFrame(() => {
        this.animatePrice = false;

        if (!Validation.isUndefinedOrNull(previousHighestBidValue) && previousHighestBidValue < currentHighestBidValue) {
          requestAnimationFrame(() => {
            this.animatePrice = true;
          });
        }
      });
    }
  }

  private isStartingBidValue(currentHighestBidValue: number) {
    return this.auction.startingBidValue === currentHighestBidValue && this.auction.startingBidValue > 0;
  }

  public showHighestBidderPopover(event: Event) {
    const content = this.auction.amIHighestBidder
      ? this.translateService.instant('auction.buyer-info.you-are-the-highest-bidder')
      : this.translateService.instant('auction.buyer-info.you-are-not-the-highest-bidder');

    this.popover.open({
      content,
      origin: event.currentTarget as HTMLElement,
    });
  }
}
