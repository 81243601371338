import { Color, Icon } from '@caronsale/enzo';
import { EFrequentBuyerStatusTypeNames } from '@cosTypes';

export const statusToColorIconMap: Record<EFrequentBuyerStatusTypeNames, { icon: Icon; color: Color }> = {
  none: { icon: 'sport', color: 'black' },
  bronze: { icon: 'trophy-bronze', color: 'orange-dark' },
  silver: { icon: 'trophy-silver', color: 'grey' },
  gold: { icon: 'trophy-gold', color: 'yellow-dark' },
  platinum: { icon: 'trophy-platinum', color: 'purple-highlight' },
} as const;
