import { Component, ElementRef, OnDestroy, Renderer2 } from '@angular/core';
import { NotificationCenterService } from '@cosCoreFeatures/common/notification-center/services/notification.service';
import { ICosNotification, IPage } from '@caronsale/cos-models';
import { BehaviorSubject, of, switchMap, tap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { LoyaltyService } from '@cosBuyer/loyalty';

@Component({
  selector: 'app-notification-component',
  templateUrl: './notification-center.component.html',
})
export class NotificationCenterComponent implements OnDestroy {
  public isNotificationDialogVisible = false;
  public countUnreadNotifications = 0;
  public notificationPage$ = new BehaviorSubject<IPage<ICosNotification>>(null);

  private removeClickHandlerFunction: () => void;

  public constructor(
    private notificationService: NotificationCenterService,
    private loyaltyService: LoyaltyService,
    private renderer2: Renderer2,
    private elementRef: ElementRef,
  ) {
    // push new pages into our notification page stream to the child
    this.notificationService.notificationPageLoaded$.pipe(takeUntilDestroyed()).subscribe(result => this.notificationPage$.next(result));

    this.notificationService.countUnreadNotifications$.pipe(takeUntilDestroyed()).subscribe(result => (this.countUnreadNotifications = result.count));
    // initial request for the unread count
    this.notificationService.getUnreadNotificationsCount();

    this.notificationService.newNotification$
      .pipe(
        tap(newNotification => {
          // new notifications do not increase the "unread" count since they are displayed immediately, either by the notification-dialog or by a toast.
          // both of them issue the "READ" engagement to the backend, so the (momentarily increased) count will decrease immediately again.
          if (this.isNotificationDialogVisible) {
            // add new notification to currentPage (notification-dialog will ignore duplicates)
            const currentPage = this.notificationPage$.value;
            if (currentPage) {
              currentPage.items.unshift(newNotification); // order actually doesn't matter, notification-dialog will sort all by date
              currentPage.total += 1;
              this.notificationPage$.next(currentPage);
            }
          } else if (newNotification.topic === 'auction.buyer.auctionGuaranteeAvailableToBook') {
            this.notificationService.showGuaranteeAdvertiseToast(newNotification);
          } else {
            // do not increase the unread count, since the toast does not
            this.notificationService.showToastIfNotOnNotificationTargetPage(newNotification);
          }
        }),
        switchMap(newNotification => {
          if (newNotification.topic === 'auction.buyer.auctionWon') {
            return this.loyaltyService.fetchStatus();
          }
          return of(newNotification);
        }),
        takeUntilDestroyed(),
      )
      .subscribe();

    this.notificationService.engagedNotifications$.pipe(takeUntilDestroyed()).subscribe(notifications => {
      // instead of trying to subtract the new "READ" engagements, ask the backend for the current unread number again
      if (notifications?.length) {
        this.notificationService.getUnreadNotificationsCount();
      }
    });

    this.notificationService.closeNotificationDialog$.pipe(takeUntilDestroyed()).subscribe(() => {
      this.setNotificationDialogVisible(false);
    });
  }

  public ngOnDestroy() {
    this.notificationService.disconnect();
    this.removeWindowClickHandler();
  }

  public toggleNotificationDialog() {
    this.setNotificationDialogVisible(!this.isNotificationDialogVisible);
  }

  private setNotificationDialogVisible(isVisible: boolean): void {
    this.isNotificationDialogVisible = isVisible;

    if (isVisible) {
      // close the notification dialog if something was clicked outside of us (that bubbled up to 'window')
      this.removeClickHandlerFunction = this.renderer2.listen('window', 'click', event => {
        if (!this.elementRef.nativeElement.contains(event.target)) {
          this.setNotificationDialogVisible(false);
        }
      });
    } else {
      this.removeWindowClickHandler();
    }
  }

  private removeWindowClickHandler(): void {
    if (this.removeClickHandlerFunction) {
      this.removeClickHandlerFunction();
      this.removeClickHandlerFunction = undefined;
    }
  }
}
