import { Component, input, Input, OnChanges, signal } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { finalize, map, Observable } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';
import { IAuction } from '@caronsale/cos-models';
import { EVehicleCreationOrigin, EVehicleExternalReportType } from '@caronsale/cos-vehicle-models';
import { CosCoreClient } from '@cosCoreServices/core-client/cos-core-client.service';
import { VehicleReportTranslatedModalComponent } from './vehicle-report-translated-modal/vehicle-report-translated-modal.component';
import { ProductAnalyticsService } from '@cosCoreServices/product-analytics/product-analytics.service';

export enum EReportType {
  DEKRA = 'dekra',
  TUV = 'tuv',
  PRIVATE = 'private',
  THIRD_PARTY = 'third-party',
  MACADAM = 'macadam',
}

const reportButtonTooltipLabels: Partial<Record<EVehicleCreationOrigin, string>> = {
  [EVehicleCreationOrigin.REVIEW_TUV_SUD]: 'TÜV Süd',
  [EVehicleCreationOrigin.REVIEW_DEKRA]: 'DEKRA',
  [EVehicleCreationOrigin.REVIEW_TUV_RHEINLAND]: 'TÜV Rheinland',
  [EVehicleCreationOrigin.REVIEW_TUV_NORD]: 'TÜV Nord',
  [EVehicleCreationOrigin.REVIEW_GTU]: 'GTÜ',
  [EVehicleCreationOrigin.REVIEW_HUSGES]: 'Hüsges',
  [EVehicleCreationOrigin.REVIEW_KUS]: 'KÜS',
  [EVehicleCreationOrigin.REVIEW_ALPHA_CONTROLLER]: 'TÜV Süd',
  [EVehicleCreationOrigin.REVIEW_VFKS]: 'VfKS',
  [EVehicleCreationOrigin.ALPHA_CONTROLLER_INTEGRATION]: 'TÜV Süd',
  [EVehicleCreationOrigin.MERCEDES_AUSTRIA]: 'TÜV Süd',
  [EVehicleCreationOrigin.MERCEDES_FRANCE]: 'DEKRA',
};

@Component({
  selector: 'app-vehicle-origin-report-button',
  templateUrl: './vehicle-origin-report-button.component.html',
  styleUrls: ['./vehicle-origin-report-button.component.scss'],
})
export class VehicleOriginReportButtonComponent implements OnChanges {
  @Input() public auction: IAuction;
  @Input() public theme: 'battery' | 'default' | 'expose' | 'expose-small' = 'default';
  @Input() public disableOpenReport = false;
  public clickOrigin = input<'List view' | 'Detail view'>();

  public isLoading = false;
  public isCosReview = false;
  public canDownload = false;
  public externalReportType;
  public EReportType = EReportType;
  public EVehicleCreationOrigin = EVehicleCreationOrigin;

  public getReportLabel: string | null = null;

  public reportButtonTooltip = signal('');

  public constructor(
    private readonly translationService: TranslateService,
    private readonly cosClient: CosCoreClient,
    private readonly dialog: MatDialog,
    private readonly snackBar: MatSnackBar,
    private productAnalyticsService: ProductAnalyticsService,
  ) {}

  public ngOnChanges(): void {
    this.isCosReview = this.auction.associatedVehicle.origin === EVehicleCreationOrigin.REVIEW_COS;
    this.externalReportType = this.getReportType(this.auction.associatedVehicle.origin);
    this.canDownload = this.isCosReview || this.theme === 'expose-small' || Boolean(this.getReportUrl());
    this.reportButtonTooltip.set(
      this.auction.associatedVehicle.externalReports?.[0]?.type === EVehicleExternalReportType.MACADAM
        ? 'Macadam'
        : reportButtonTooltipLabels[this.auction.associatedVehicle.origin] || 'vehicle.origin.unknown-expert',
    );
    this.getReportLabel =
      {
        [EVehicleCreationOrigin.ALPHA_CONTROLLER_INTEGRATION]: 'vehicle.origin.report-tuv',
        [EVehicleCreationOrigin.MERCEDES_AUSTRIA]: 'vehicle.origin.report-tuv',
        [EVehicleCreationOrigin.MERCEDES_FRANCE]:
          this.auction.associatedVehicle.externalReports?.[0]?.type === EVehicleExternalReportType.MACADAM
            ? 'vehicle.origin.report-macadam'
            : 'vehicle.origin.report-dekra',
      }[this.auction.associatedVehicle.origin] || null;
  }

  public openReport(): void {
    if (this.disableOpenReport) {
      return;
    }

    if (this.clickOrigin) {
      this.productAnalyticsService.trackEvent('reportViewed', {
        'Report type': this.isCosReview ? 'COS' : 'External',
        Origin: this.clickOrigin(),
        'Auction uuid': this.auction.uuid,
      });
    }

    const reportUrl = this.getReportUrl();

    if (reportUrl) {
      // Prevent browser from using locally-cached file
      window.open(reportUrl + `?t=${new Date().getTime()}`, '_blank');
      return;
    }

    if (this.externalReportType === EReportType.PRIVATE && !reportUrl) {
      return;
    }

    const currentLang = this.translationService.currentLang;
    this.isLoading = true;

    this.translateReport(currentLang)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: url => {
          this.auction.associatedVehicle.urlsByLanguageToExpose[currentLang] = url;
          this.dialog.open(VehicleReportTranslatedModalComponent, {
            width: '600px',
            data: { auction: this.auction, reportUrl: url },
          });
        },
        error: errorResponse => {
          const errorMessage: string = this.translationService.instant('vehicle.expose-translation.error', { error: errorResponse.error.message });
          this.snackBar.open(errorMessage, null, { duration: 5000 });
        },
      });
  }

  public openBatteryCertificate() {
    const certificateUrl = this.auction.associatedVehicle.urlToBatteryConditionCertificate;
    if (certificateUrl) {
      window.open(certificateUrl, '_blank');
      return;
    }
  }

  private translateReport(language: string): Observable<string> {
    return this.cosClient.requestWithPrivileges('post', `/media/auction/${this.auction.uuid}/summary/${language}`).pipe(map(res => res.url));
  }

  private getReportUrl(): string {
    if (this.isCosReview || this.theme === 'expose-small') {
      return this.auction.associatedVehicle.urlsByLanguageToExpose?.[this.translationService.currentLang];
    }
    return this.auction.associatedVehicle.urlToAttachment1;
  }

  private getReportType(vehicleReport: EVehicleCreationOrigin): EReportType {
    switch (vehicleReport) {
      case EVehicleCreationOrigin.REVIEW_DEKRA:
        return EReportType.DEKRA;
      case EVehicleCreationOrigin.REVIEW_TUV_NORD:
      case EVehicleCreationOrigin.REVIEW_TUV_RHEINLAND:
      case EVehicleCreationOrigin.REVIEW_TUV_SUD:
      case EVehicleCreationOrigin.MERCEDES_AUSTRIA:
      case EVehicleCreationOrigin.ALPHA_CONTROLLER_INTEGRATION:
        return EReportType.TUV;
      case EVehicleCreationOrigin.SELF_REVIEWED:
        return EReportType.PRIVATE;
      case EVehicleCreationOrigin.MERCEDES_FRANCE:
        return this.auction.associatedVehicle.externalReports?.[0]?.type === EVehicleExternalReportType.MACADAM ? EReportType.MACADAM : EReportType.DEKRA;
      default:
        return EReportType.THIRD_PARTY;
    }
  }
}
