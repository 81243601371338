import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EnzoComponentsModule } from '@enzo';

import { CosPopoverModule } from '@cosCoreFeatures/@cos/cos-popover/cos-popover.module';
import { MobileBidDialogComponent } from './mobile-bid-dialog.component';
import { BaseModule } from '@cosCoreComponents/general/base.module';

@NgModule({
  declarations: [MobileBidDialogComponent],
  exports: [MobileBidDialogComponent],
  imports: [CommonModule, BaseModule, EnzoComponentsModule, CosPopoverModule],
})
export class MobileBidDialogModule {}
