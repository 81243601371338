import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { EnzoBannerComponent, EnzoTextComponent } from '@enzo';
import { LoyaltyDiscountAwareComponent } from '@cosBuyer/partials/utils/loyalty-discount-aware.component';
import { TranslateModule } from '@ngx-translate/core';
import { ERoute } from '@cosCoreModules/app-routes';

@Component({
  standalone: true,
  selector: 'app-loyalty-banner',
  templateUrl: './loyalty-banner.component.html',
  imports: [EnzoBannerComponent, EnzoTextComponent, TranslateModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoyaltyBannerComponent extends LoyaltyDiscountAwareComponent {
  public readonly loyaltyProgramURL = ERoute.BUYER_LOYALTY_PROGRAM;

  private router = inject(Router);

  public handleBannerClick() {
    this.router.navigate([this.loyaltyProgramURL]);
  }
}
