<div *ngIf="!confirmed">
  <h1>{{ 'self-pickup.title' | translate }}</h1>
  <p>{{ 'self-pickup.description' | translate }}</p>

  <div>
    <app-pin-code (pinComplete)="pin = $event" [numberDigits]="6" />
  </div>

  <re-captcha [siteKey]="siteKey" (resolved)="captchaIsResolved($event)" />

  <button mat-flat-button type="submit" color="primary" (click)="submit()" [disabled]="isSubmitDisabled()">{{ 'self-pickup.verify-btn' | translate }}</button>
</div>

<div *ngIf="confirmed">
  <app-pickup-confirmation-success [vehicle]="vehicle" />
</div>
