import { booleanAttribute, ChangeDetectionStrategy, Component, input, numberAttribute } from '@angular/core';
import { EnzoComponentsModule } from '@enzo';
import { Color, Icon } from '@caronsale/enzo';
import { TestingModule } from '@cos/components/general/testing/testing.module';

@Component({
  selector: 'app-header-button',
  standalone: true,
  imports: [EnzoComponentsModule, TestingModule],
  templateUrl: './header-button.component.html',
  styleUrls: ['./header-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderButtonComponent {
  public icon = input.required<Icon>();
  public iconColor = input<Color>('white');
  public badgeCount = input(0, { transform: numberAttribute });
  public badgeColor = input<Color>('red');
  public active = input(false, { transform: booleanAttribute });
}
