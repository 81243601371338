<app-expandable-panel [expandedState]="true">
  <div panelTitle class="panel-title">
    <enzo-icon [icon]="'info-outline'" />
    <enzo-headline level="4" color="blackish">{{ 'vehicle.additional-info' | translate }}</enzo-headline>
  </div>
  <div panelContent class="panel-content">
    <ul [class.single-item]="additionalInfos().length === 1">
      @for (info of additionalInfos(); let idx = $index; track idx) {
        <li>
          <enzo-text>{{ info }}</enzo-text>
        </li>
      } @empty {
        <enzo-text>{{ 'vehicle.additional-info-not-available' | translate }}</enzo-text>
      }
    </ul>
  </div>
</app-expandable-panel>
