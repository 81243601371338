import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { COS_CONSTANTS } from '@cosCoreServices/constants/cos-constants.service';

@Component({
  selector: 'app-imprint',
  templateUrl: './imprint.component.html',
  styleUrls: ['./imprint.component.scss'],
})
export class ImprintComponent {
  public constructor(
    public dialogRef: MatDialogRef<ImprintComponent>,
    public constants: COS_CONSTANTS,
  ) {}

  public close(): void {
    this.dialogRef.close();
  }
}
