<ng-container *ngIf="paintStates.length > 0">
  <div class="title-container">
    <enzo-text kind="body-bold">{{ 'vehicle.paint-thickness' | translate }}</enzo-text>
    <div class="inline-text">
      <enzo-text kind="caption" icon="info-outline" iconSize="small">&#181;m {{ 'vehicle.paint-thickness-text' | translate }}</enzo-text>
      <enzo-text *ngIf="hasUnusualValues" kind="caption" icon="warning" iconColor="orange" iconSize="small">
        {{ 'vehicle.condition.abnormality' | translate }}
      </enzo-text>
    </div>
  </div>
  <enzo-text *ngIf="displayNotice" kind="caption" color="orange" icon="info-outline" iconColor="blackish" iconSize="small">
    {{ 'vehicle.paint-thickness-notice' | translate }}
  </enzo-text>
  <app-vehicle-details-grid [bgColorOnOdds]="true">
    <ng-container *ngFor="let state of paintStates">
      <div *ngIf="state.value">
        <enzo-text kind="overline" color="grey-dark">{{ state.part | i18nPaintStateParts | translate }}</enzo-text>
        <div class="inline-text">
          <enzo-text>{{ state.value + ' &#181;m' }}</enzo-text>
          <enzo-icon *ngIf="state.isUnusualValue" icon="warning" color="orange" size="small" />
        </div>
      </div>
    </ng-container>
  </app-vehicle-details-grid>
</ng-container>
