<div class="dialog-close" data-cy="mobile-banner_close-button" matDialogClose matTooltip="{{ 'action.close-dialog' | translate }}">
  <fa-icon icon="times" size="2x" />
</div>
<div class="banner" (click)="openApp()">
  <h1>{{ 'overlay-banner.header' | translate }}</h1>
  <p class="banner-text">{{ 'overlay-banner.text' | translate }}</p>
  <p class="banner-join">{{ 'overlay-banner.join' | translate }}</p>
  <img src="/assets/logo/apple/app_store.svg" *ngIf="platform.IOS" />
  <img src="/assets/logo/android/google_play.svg" *ngIf="platform.ANDROID" />
  @if (debugString) {
    <div class="debug-string">{{ debugString }}</div>
  }
</div>
