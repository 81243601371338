<ng-container *ngIf="isInstantPurchaseActive()">
  <enzo-button
    variant="highlight"
    [label]="'action.buy-now-for' | translate: { price: auction.instantPurchasePrice | currency: 'EUR' }"
    (enzoClick)="makeInstantPurchase()"
    testid="instant-purchase-button"
    stretch />

  <app-cross-border-discount-badge testid="app-cross-border-discount-badge" [amount]="auction.buyerCrossBorderProcessingAmount" />

  <div class="fees-and-taxes">
    <app-auction-vat [auction]="auction" />
    <app-fee-info [auction]="auction" [highestBidOrNextBid]="auction.instantPurchasePrice" [currentPrebookedServices]="currentPrebookedServices" />
  </div>
  <enzo-text kind="caption" align="center">{{ 'auction.instant-purchase-info-text' | translate }}</enzo-text>
</ng-container>
