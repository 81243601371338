import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EnzoComponentsModule } from '@enzo';

import { CosPopoverModule } from '@cosCoreFeatures/@cos/cos-popover/cos-popover.module';
import { BiddingActionsComponent } from './bidding-actions.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    //
    BiddingActionsComponent,
  ],
  exports: [
    //
    BiddingActionsComponent,
  ],
  imports: [
    //
    CommonModule,
    EnzoComponentsModule,
    CosPopoverModule,
    TranslateModule,
    MatTooltipModule,
    ReactiveFormsModule,
  ],
})
export class BiddingActionsModule {}
