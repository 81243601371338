@if (selectedTierConfig(); as config) {
  <enzo-banner
    [ctaHref]="loyaltyProgramURL"
    [label]="config.translationKey | translate"
    [icon]="config.icon"
    [kind]="config.variation"
    [ctaLabel]="'general.get-to-know-more' | translate"
    (ctaClick)="handleBannerClick()"
    testid="loyalty-banner">
    <enzo-text kind="label" color="black-light">
      {{ 'buyer.loyalty-program.fee-discount' | translate: { percentage: activeLoyaltyProgramDiscountPercentage() } }}
    </enzo-text>
  </enzo-banner>
}
