<app-pickup-info [purchase]="auction" />

<button
  mat-flat-button
  class="full-width"
  *ngIf="auction.amIHighestBidder && (auction.transportationTask | isTransportBooked)"
  [ngClass]="(auction.transportationTask | isTransportDelivered) ? 'cos-success' : 'mat-primary'"
  (click)="showTransportationInfo($event)">
  <ng-container *ngIf="auction.transportationTask | isTransportDelivered; else notDelivered">
    <fa-icon icon="check" />
    {{ 'transport.vehicle-delivered' | translate }}
  </ng-container>

  <ng-template #notDelivered>
    <fa-icon icon="truck" />
    {{ 'transport.status' | translate }}
  </ng-template>
</button>
