import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { EAirCondition, ECoupling, EHeadlights, EParkingAssistance, EVehicleHeater, IVehicle } from '@caronsale/cos-vehicle-models';
import { CommonModule } from '@angular/common';
import { EnzoComponentsModule } from '@enzo';
import { TranslateModule } from '@ngx-translate/core';
import { BaseModule } from '@cosCoreComponents/general/base.module';

interface IEquipmentConfig {
  icon: string;
  nameKey?: string;
  equipmentKey: keyof IVehicle;
  predicate: (IVehicle) => boolean;
  detail?: string;
}

const EquipmentDetailMappings = {
  ac: {
    [EAirCondition.UNKNOWN]: 'vehicle.ac-label.unknown',
    [EAirCondition.NONE]: 'vehicle.ac-label.none',
    [EAirCondition.AC]: 'vehicle.ac-label.standard',
    [EAirCondition.AUTO_AC]: 'vehicle.ac-label.automatic',
  },
  coupling: {
    [ECoupling.NONE]: 'vehicle.coupling-label.none',
    [ECoupling.DETACHABLE]: 'vehicle.coupling-label.detachable',
    [ECoupling.FIXED]: 'vehicle.coupling-label.fix',
    [ECoupling.SWIVELING]: 'vehicle.coupling-label.swiveling',
  },
  headlights: {
    [EHeadlights.HALOGEN]: 'vehicle.headlights-label.halogen',
    [EHeadlights.XENON]: 'vehicle.headlights-label.xenon',
    [EHeadlights.BI_XENON]: 'vehicle.headlights-label.bi-xenon',
    [EHeadlights.LED]: 'vehicle.headlights-label.led',
  },
  vehicleHeater: {
    [EVehicleHeater.NONE]: 'vehicle.heater-label.none',
    [EVehicleHeater.WITHOUT_REMOTE]: 'vehicle.heater-label.without-remote',
    [EVehicleHeater.WITH_REMOTE]: 'vehicle.heater-label.with-remote',
  },
  parkingAssistance: {
    [EParkingAssistance.NONE]: 'vehicle.parking-assistance-label.none',
    [EParkingAssistance.FRONT]: 'vehicle.parking-assistance-label.front',
    [EParkingAssistance.BACK]: 'vehicle.parking-assistance-label.back',
    [EParkingAssistance.FRONT_AND_BACK]: 'vehicle.parking-assistance-label.front-and-back',
  },
};

const EQUIPMENT_CONFIGS: IEquipmentConfig[] = [
  {
    icon: 'ac',
    nameKey: 'vehicle.equipment-highlights.ac',
    equipmentKey: 'ac',
    predicate: ({ ac }) => ac === EAirCondition.AC || ac === EAirCondition.AUTO_AC,
  },
  {
    icon: 'coupling',
    nameKey: 'vehicle.equipment-highlights.coupling',
    equipmentKey: 'coupling',
    predicate: ({ coupling }) => coupling === ECoupling.DETACHABLE || coupling === ECoupling.FIXED || coupling === ECoupling.SWIVELING,
  },
  {
    icon: 'car-lights',
    nameKey: 'vehicle.equipment-highlights.headlights',
    equipmentKey: 'headlights',
    predicate: ({ headlights }) => headlights === EHeadlights.XENON || headlights === EHeadlights.BI_XENON || headlights === EHeadlights.LED,
  },
  {
    icon: 'navigation',
    equipmentKey: 'navigation',
    predicate: ({ navigation }) => navigation === 1,
    detail: 'vehicle.equipment-highlights.navigation-system',
  },
  {
    icon: 'parking-heater',
    nameKey: 'vehicle.equipment-highlights.heating',
    equipmentKey: 'vehicleHeater',
    predicate: ({ vehicleHeater }) => vehicleHeater === EVehicleHeater.WITH_REMOTE || vehicleHeater === EVehicleHeater.WITHOUT_REMOTE,
  },
  {
    icon: 'parking-assistant',
    nameKey: 'vehicle.equipment-highlights.parking-assistance',
    equipmentKey: 'parkingAssistance',
    predicate: ({ parkingAssistance }) =>
      parkingAssistance === EParkingAssistance.BACK ||
      parkingAssistance === EParkingAssistance.FRONT ||
      parkingAssistance === EParkingAssistance.FRONT_AND_BACK,
  },
  {
    icon: 'sunroof',
    detail: 'vehicle.equipment-highlights.sunroof',
    equipmentKey: 'sunRoof',
    predicate: ({ sunRoof }) => sunRoof === 1,
  },
  {
    icon: 'sport',
    detail: 'vehicle.equipment-highlights.sport-package',
    equipmentKey: 'sportPackage',
    predicate: ({ sportPackage }) => sportPackage === 1,
  },
];

@Component({
  standalone: true,
  imports: [TranslateModule, CommonModule, EnzoComponentsModule, BaseModule],
  selector: 'app-vehicle-equipment-icons',
  templateUrl: './vehicle-equipment-icons.component.html',
  styleUrls: ['./vehicle-equipment-icons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VehicleEquipmentIconsComponent {
  @Input()
  public set vehicle(vehicle: IVehicle) {
    this.equipments = EQUIPMENT_CONFIGS.filter(eqConfig => eqConfig.predicate(vehicle))
      .map(eqConfig => ({
        ...eqConfig,
        detail: this.getEquipmentDetail(vehicle, eqConfig.equipmentKey, eqConfig.detail),
      }))
      .filter(eq => eq.detail);
  }

  public equipments: IEquipmentConfig[];

  private getEquipmentDetail(vehicle: IVehicle, equipmentKey: keyof IVehicle, defaultDetail?: string): string {
    const equipmentValue = vehicle[equipmentKey];
    const mappings = EquipmentDetailMappings[equipmentKey];

    return mappings && equipmentValue !== undefined ? mappings[equipmentValue] || defaultDetail : defaultDetail;
  }
}
