<ng-container *ngIf="vm$ | async as vm">
  <div class="header">
    <enzo-headline level="2">{{ vm.auction.label }}</enzo-headline>
    @if (browserDetectionService.isDesktopWidth$ | async) {
      <ng-container *ngTemplateOutlet="statusInfo" />
    }
  </div>
  <div class="vehicle-images-and-bid-info-container">
    <div class="vehicle-images-container">
      <div class="sub-headline">
        <div class="sub-vehicle-info">
          <enzo-flag [country]="vm.auction.locationCountryCode" [matTooltip]="vm.auction.locationCountryCode | i18nCountryCode | translate" />
          <enzo-text kind="label" color="black-light" icon="dot" iconSize="small" iconSpacing="1">{{ vm.auction.associatedVehicle.ez }}</enzo-text>
          <enzo-text kind="label" color="black-light" icon="dot" iconSize="small" iconSpacing="1">
            {{ vm.auction.associatedVehicle.mileageInKm | mileage }}
          </enzo-text>
          <enzo-text kind="label" color="black-light" icon="dot" iconSize="small" iconSpacing="1">
            {{ vm.auction.associatedVehicle.fuelType | i18nVehicleFuelType | translate }}
          </enzo-text>
          <enzo-text kind="label" color="black-light" icon="dot" iconSize="small" iconSpacing="1">
            {{ vm.auction.associatedVehicle.transmission | i18nVehicleTransmission | translate }}
          </enzo-text>
          <enzo-text kind="label" color="black-light" icon="dot" iconSize="small" iconSpacing="1">
            {{ 'unit.power-x-hp' | translate: { value: vm.auction.associatedVehicle.enginePowerInHp } }}
          </enzo-text>
        </div>

        <div class="auction-id">
          <enzo-text kind="label" color="black-light">{{ 'general.offer-id' | translate }} {{ vm.auction.id | auctionIdentifier }}</enzo-text>
          <app-live-auction-info *ngIf="vm.auction.isLive" />
        </div>
      </div>

      <mat-tab-group testid="vehicle-tabs">
        <mat-tab *ngIf="vm.vehicleGalleryImages.length > 0">
          <ng-template mat-tab-label>
            <enzo-text kind="emphasis-label" icon="gallery" iconSize="small">{{ 'general.all-images' | translate }}</enzo-text>
          </ng-template>
          <ng-template matTabContent>
            <div class="gallery-container">
              <div class="interests-container">
                <app-watchlist-button *ngIf="!!vm.auctionBuyer" [auction]="vm.auctionBuyer" watchlistLocation="Detail view" />
                <app-notes-button [auctionUuid]="vm.auction.uuid" />
              </div>
              <app-image-gallery [galleryImages]="vm.vehicleGalleryImages" testid="vehicle-images" />
            </div>
          </ng-template>
        </mat-tab>

        <mat-tab *ngIf="vm.vehicleVideoUrl.length > 0">
          <ng-template mat-tab-label>
            <enzo-text kind="emphasis-label" icon="play-arrow" iconSize="small">{{ 'general.video' | translate }}</enzo-text>
          </ng-template>
          <ng-template matTabContent>
            <app-video-gallery [videoUrls]="vm.vehicleVideoUrl" />
          </ng-template>
        </mat-tab>

        <mat-tab *ngIf="vm.motorVideoUrl && vm.motorVideoUrl.length > 0">
          <ng-template mat-tab-label>
            <enzo-text kind="emphasis-label" icon="play-arrow" iconSize="small">{{ 'vehicle.motor-video' | translate }}</enzo-text>
          </ng-template>
          <ng-template matTabContent>
            <app-video-gallery [videoUrls]="vm.motorVideoUrl" />
          </ng-template>
        </mat-tab>

        <mat-tab *ngIf="vm.damageGalleryImages.length > 0">
          <ng-template mat-tab-label>
            <enzo-text kind="emphasis-label" icon="condition" iconSize="small">{{ 'general.condition-images' | translate }}</enzo-text>
          </ng-template>
          <ng-template matTabContent>
            <div class="gallery-container">
              <app-image-gallery [galleryImages]="vm.damageGalleryImages" testid="vehicle-damages" />
            </div>
          </ng-template>
        </mat-tab>

        <mat-tab *ngIf="vm.documentGalleryImages.length > 0">
          <ng-template mat-tab-label>
            <enzo-text kind="emphasis-label" icon="document" iconSize="small">{{ 'general.documents' | translate }}</enzo-text>
          </ng-template>
          <ng-template matTabContent>
            <div class="gallery-container">
              <app-image-gallery [galleryImages]="vm.documentGalleryImages" />
            </div>
          </ng-template>
        </mat-tab>
      </mat-tab-group>

      @if (displayPremiumSellerBanner()) {
        <div class="premium-seller-banner">
          <div>
            <span>
              <enzo-icon icon="star-small" size="small" color="yellow" />
              <enzo-icon icon="star-small" color="yellow" />
              <enzo-icon icon="premium-seller" color="yellow" />
              <enzo-icon icon="star-small" color="yellow" />
              <enzo-icon icon="star-small" size="small" color="yellow" />
            </span>
            <enzo-headline level="6" color="white" align="center">{{ 'auction.premium-seller.label' | translate }}</enzo-headline>
            <div class="hex"></div>
            <div class="hex"></div>
          </div>
          <div>
            <enzo-text kind="emphasis-label">{{ 'auction.premium-seller.percentage' | translate }}</enzo-text>
            <enzo-text
              kind="label"
              color="black-light"
              [innerHTML]="
                'auction.premium-seller.description.message'
                  | translate: { messageTemplate: '{piece-1} \<b>{piece-2}\</b> {piece-3} \<b>{piece-4}\</b> {piece-5}' }
              " />
          </div>
        </div>
      }
    </div>

    <div class="bid-info-container">
      @if (browserDetectionService.isMobileOrTabletWidth$ | async) {
        <ng-container *ngTemplateOutlet="statusInfo" />
      }
      <ng-content select="[bid-info]" />
    </div>
  </div>
  <div class="report-document-container">
    <div *ngIf="displayReportButton" class="report-document-item">
      <app-vehicle-origin-report-button [clickOrigin]="'Detail view'" [auction]="vm.auction" #reportButton />
      <enzo-ghost-button
        *ngIf="vm.auction.associatedVehicle.origin !== eVehicleCreationOrigin.SELF_REVIEWED && vm.auction.associatedVehicle.urlToAttachment1"
        [label]="'general.view-condition-report' | translate"
        variant="highlight"
        (enzoClick)="reportButton.openReport()" />
    </div>
    <div *ngIf="vm.auction.associatedVehicle.urlToBatteryConditionCertificate" class="report-document-item">
      <app-vehicle-origin-report-button [clickOrigin]="'Detail view'" [auction]="vm.auction" [theme]="'battery'" #certificateButton />
      <enzo-ghost-button
        label="{{ 'vehicle.battery.certificate' | translate }} {{ 'general.show' | translate }}"
        variant="highlight"
        (enzoClick)="certificateButton.openBatteryCertificate()" />
    </div>
  </div>

  <div class="seller-progress-diagram-container">
    <ng-content select="[seller-progress-diagram]" />
  </div>

  <app-vehicle-equipment-icons [vehicle]="vm.auction.associatedVehicle" />

  <ng-template #vehicleCondition><app-vehicle-condition-data [auction]="vm.auction" /></ng-template>
  <ng-template #additionalInfo><app-vehicle-additional-info [vehicle]="vm.auction.associatedVehicle" /></ng-template>
  <ng-template #specificInfo><app-specific-info [auction]="vm.auction" /></ng-template>
  <ng-template #documentInfo><app-document-info [auction]="vm.auction" [auctionSeller]="vm.auctionSeller" /></ng-template>
  <ng-template #equipmentInfo><app-equipment-info [auction]="vm.auction" /></ng-template>

  @for (section of (advPreferencesService.advPreferences$ | async).sectionOrder; track section) {
    <ng-container *ngTemplateOutlet="sectionTemplateMapping[section]?.()" />
  }

  <app-vehicle-unspecified-info />

  <ng-template #statusInfo>
    <app-status-info [auctionBuyer]="auctionBuyer" [auctionSeller]="auctionSeller" progressBar="hot-bid-bottom">
      <app-bids-amount *ngIf="auctionBuyer" [auction]="auctionBuyer" bids-amount />
    </app-status-info>
  </ng-template>

  <ng-template #badgeOverlayTemplate>
    <div class="badges">
      @if (vm.auctionBuyer?.isExpressPickupAvailable) {
        <app-auction-badge [icon]="{ enzoIcon: 'express-pickup' }" color="white" label="pickup.express-pickup" testid="express-pick-up-badge" />
      }
      @if (vm.auctionHighlights?.length) {
        @for (highlight of vm.auctionHighlights; track highlight.key) {
          <app-auction-badge
            [color]="highlight.color"
            [label]="highlight.labelKey | translate"
            [icon]="highlight.icon"
            [highlight]="true"
            testid="highlight-badge" />
        }
      } @else if (!!vm.auctionBuyer && !vm.auction.isTest) {
        <app-auction-badge
          color="yellow"
          [label]="vm.exclusiveBadgeContent.key"
          [icon]="vm.exclusiveBadgeContent.icon ? { iconFromSource: vm.exclusiveBadgeContent.icon } : { enzoIcon: 'exclusive' }"
          testid="exclusive-badge" />
      } @else if (!!vm.auctionBuyer && vm.auctionBuyer.sellerType === ESellerBusinessType.PRIVATE) {
        <app-auction-badge [icon]="{ enzoIcon: 'private-owner' }" color="yellow" label="auction.seller-type-label.private" testid="private-badge" />
      }
    </div>
    <app-premium-seller-badge [sellerUuid]="vm.auction._fk_uuid_sellerUser" />
  </ng-template>
</ng-container>
