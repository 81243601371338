import { Component, Input } from '@angular/core';
import { IVehicle } from '@caronsale/cos-vehicle-models';
import { COS_CONSTANTS } from '@cosCoreServices/constants/cos-constants.service';

@Component({
  selector: 'app-pickup-confirmation-success',
  templateUrl: './pickup-confirmation-success.component.html',
  styleUrls: ['./pickup-confirmation-success.component.scss'],
})
export class PickupConfirmationSuccessComponent {
  @Input()
  public vehicle: IVehicle;

  public cosLink = this.constants.APP_LOGIN_PAGE;

  public constructor(private constants: COS_CONSTANTS) {}

  // make + model + year
  public get vehicleInfo(): string {
    return `${this.vehicle.make}, ${this.vehicle.model}, ${this.vehicle.ez}`;
  }

  public get vin(): string {
    return this.vehicle.vin;
  }

  public get imgUrl(): string {
    return `url(${this.vehicle.vehicleImages[0].url})`;
  }
}
