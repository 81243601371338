import { computed, inject, Injectable, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { catchError, EMPTY, filter, Observable, retry, switchMap, take, tap } from 'rxjs';

import { IFrequentBuyerStatusAndSummary, TAnimationType } from '@cosTypes';
import { CosCoreClient } from '@cosCoreServices/core-client/cos-core-client.service';
import { ProductAnalyticsService } from '@cosCoreServices/product-analytics/product-analytics.service';

// Inspired by https://angular.dev/guide/signals/resource#resource-loaders
enum ResourceStatus {
  Idle = 'Idle', // The resource has not been loaded yet.
  Error = 'Error', // An error occurred while loading the resource after RESOURCE_RETRIES retries.
  Loading = 'Loading', // The resource is being loaded.
  Resolved = 'Resolved', // The resource has been successfully loaded.
}

const RESOURCE_RETRIES = 3;

const animationPropMap: Record<
  TAnimationType,
  keyof Pick<IFrequentBuyerStatusAndSummary['statusSummary'], 'headerAnimationViewedAt' | 'detailsAnimationViewedAt'>
> = {
  [TAnimationType.Header]: 'headerAnimationViewedAt',
  [TAnimationType.Details]: 'detailsAnimationViewedAt',
};

export const animationTimers = { total: 3000, slideOut: 1000 };

@Injectable()
export class LoyaltyService {
  private cosCoreClient = inject(CosCoreClient);
  private productAnalyticsService = inject(ProductAnalyticsService);

  private resourceStatus = signal<ResourceStatus>(ResourceStatus.Idle);
  private internalState = signal<IFrequentBuyerStatusAndSummary>(null);

  public state = this.internalState.asReadonly();

  public isResourceIdle = computed(() => this.resourceStatus() === ResourceStatus.Idle);
  public isResourceResolved = computed(() => this.resourceStatus() === ResourceStatus.Resolved);
  public isResourceLoading = computed(() => this.resourceStatus() === ResourceStatus.Loading);
  public isResourceError = computed(() => this.resourceStatus() === ResourceStatus.Error);

  public currentStatus = computed(() => {
    const state = this.internalState();

    if (!state) {
      return null;
    }
    return state.statusSummary.otherStatuses.find(({ uuid }) => uuid === state.status._fk_uuid_statusType).statusName;
  });

  public nextStatus = computed(() => {
    const state = this.internalState();

    if (!state) {
      return null;
    }
    return state.statusSummary.otherStatuses.find(({ uuid }) => uuid === state.statusSummary._fk_uuid_nextStatus).statusName;
  });

  public requiredPurchases = computed(() => {
    const state = this.internalState();

    if (!state) {
      return null;
    }

    const summary = state.statusSummary;

    if (summary.retainCurrentStatusPurchasesNeeded === 0) {
      const status = this.nextStatus();
      return {
        status,
        count: this.formatVehicleCount(summary.nextStatusPurchasesNeeded),
        pageKey: summary.nextStatusPurchasesNeeded === 1 ? 'buyer.loyalty-program.to-achieve' : 'buyer.loyalty-program.to-achieves',
        headerKey: 'buyer.loyalty-program.header-to-achieve',
      };
    } else {
      const status = this.currentStatus();
      return {
        status,
        count: this.formatVehicleCount(summary.retainCurrentStatusPurchasesNeeded),
        pageKey: 'buyer.loyalty-program.to-remain',
        headerKey: 'buyer.loyalty-program.header-to-remain',
      };
    }
  });

  public currentPurchases = computed<{ count: number | string; label: string }>(() => {
    const state = this.internalState();

    if (!state) {
      return null;
    }
    return {
      count: this.formatVehicleCount(state.statusSummary.currentPurchasesCount),
      label: state.statusSummary.currentPurchasesCount === 1 ? 'buyer.loyalty-program.vehicle-purchased' : 'buyer.loyalty-program.vehicles-purchased',
    };
  });

  public statuses = computed(() => {
    const state = this.internalState();

    if (!state) {
      return null;
    }

    const summary = state.statusSummary;
    const statuses = [...summary.otherStatuses].reverse();
    return statuses.map((status, index) => {
      if (statuses[index + 1]) {
        const percentageState = (summary.currentPurchasesCount - statuses[index].threshold) / (statuses[index + 1].threshold - statuses[index].threshold);

        return { ...status, filledPercentage: `${percentageState < 0 ? 0 : percentageState < 1 ? percentageState * 100 : 100}%` };
      }

      if (summary.currentPurchasesCount >= statuses.at(-1).threshold) {
        return { ...status, filledPercentage: '100%' };
      }
      return { ...status, filledPercentage: '0%' };
    });
  });

  public constructor() {
    this.productAnalyticsService
      .isOn('eg-frequent-buyer-program')
      .pipe(
        filter(Boolean),
        switchMap(() => this.getStatus()),
        take(1),
        takeUntilDestroyed(),
      )
      .subscribe();
  }

  public formatVehicleCount(count: number): string | number {
    return count <= 9 ? ('0' + count).slice(-2) : count;
  }

  public fetchStatus(): Observable<IFrequentBuyerStatusAndSummary> {
    return this.productAnalyticsService.isOn('eg-frequent-buyer-program').pipe(
      switchMap(isOn => {
        if (isOn) return this.getStatus();
        return EMPTY;
      }),
      take(1),
    );
  }

  private getStatus(): Observable<IFrequentBuyerStatusAndSummary> {
    this.resourceStatus.set(ResourceStatus.Loading);

    return this.cosCoreClient.requestWithPrivileges('get', `/frequent-buyer-status`).pipe(
      tap((response: IFrequentBuyerStatusAndSummary) => {
        this.internalState.set(response);
        this.resourceStatus.set(ResourceStatus.Resolved);
      }),
      retry(RESOURCE_RETRIES),
      catchError(() => {
        this.resourceStatus.set(ResourceStatus.Error);
        return EMPTY;
      }),
    );
  }

  public setAnimationViewed(animationTypes: TAnimationType[]): Observable<IFrequentBuyerStatusAndSummary> {
    const propsTriggered = animationTypes.reduce((statusSummary, animationType) => {
      statusSummary[animationPropMap[animationType]] = new Date();
      return statusSummary;
    }, {});

    setTimeout(
      () => this.internalState.update(state => ({ ...state, statusSummary: { ...state.statusSummary, ...propsTriggered } })),
      animationTimers.total - animationTimers.slideOut,
    );

    return this.cosCoreClient.requestWithPrivileges('post', '/frequent-buyer-status/animation/viewed', { animationTypes }).pipe(retry(RESOURCE_RETRIES));
  }
}
