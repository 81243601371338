import {
  CUSTOM_ELEMENTS_SCHEMA,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  computed,
  effect,
  inject,
  input,
  model,
  untracked,
  viewChild,
} from '@angular/core';
import { NgOptimizedImage } from '@angular/common';
import { EnzoComponentsModule } from '@enzo';
import { IGalleryImage } from '@cos/partials/general-info/image-gallery/image-gallery.component';
import { SwiperDirective } from '@cos/partials/swiper/swiper.directive';
import { SwiperOptions } from 'swiper/types';
import { SwiperContainer } from 'swiper/element';

@Component({
  standalone: true,
  selector: 'app-image-gallery-thumbnails',
  templateUrl: './image-gallery-thumbnails.component.html',
  styleUrls: ['./image-gallery-thumbnails.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [EnzoComponentsModule, NgOptimizedImage, SwiperDirective],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ImageGalleryThumbnailsComponent {
  private elementRef = inject(ElementRef);
  public selectedIndex = model<number>();
  public thumbnails = input.required<IGalleryImage[]>();
  public thumbnailWidth = 140;
  public swiperContainer = viewChild.required<ElementRef<SwiperContainer>>('swiper');
  public displayNavigation = computed<boolean>(() => this.thumbnails().length * this.thumbnailWidth > this.elementRef.nativeElement.offsetWidth);

  public swiperConfig: SwiperOptions = {
    rewind: true,
    spaceBetween: 8,
    slidesPerView: 'auto',
    centerInsufficientSlides: true,
  };

  public constructor() {
    effect(() => {
      const swiper = untracked(this.swiperContainer).nativeElement.swiper;
      const indexDifference = this.selectedIndex() - swiper?.activeIndex;

      if (indexDifference < 0 || indexDifference >= swiper?.slidesPerViewDynamic() - 1 || this.selectedIndex() === this.thumbnails().length - 1) {
        swiper?.slideTo(this.selectedIndex());
      }
    });
  }

  public slidePrevious(): void {
    const slidesPerView = this.swiperContainer().nativeElement.swiper.slidesPerViewDynamic();
    let previousSection: number;

    if (this.selectedIndex() % (slidesPerView - 1) === 0) {
      previousSection = this.selectedIndex() + 1 - slidesPerView;
    } else {
      previousSection = this.selectedIndex() + 1 - slidesPerView - (this.selectedIndex() % (slidesPerView - 1));
    }

    if (previousSection >= 0) {
      this.selectedIndex.set(previousSection);
    } else {
      this.selectedIndex.set(this.thumbnails().length - 1);
    }
  }

  public slideNext(): void {
    const slidesPerView = this.swiperContainer().nativeElement.swiper.slidesPerViewDynamic();
    let nextSection: number;

    if (this.selectedIndex() % (slidesPerView - 1) === 0) {
      nextSection = this.selectedIndex() + slidesPerView - 1;
    } else {
      nextSection = this.selectedIndex() + slidesPerView - 1 - (this.selectedIndex() % (slidesPerView - 1));
    }

    if (nextSection < this.thumbnails().length) {
      this.selectedIndex.set(nextSection);
    } else {
      this.selectedIndex.set(0);
    }
  }
}
