@for (tiresSet of tireSets(); track tiresSet.type; let index = $index) {
  <div class="tire-set-container">
    <div class="header">
      <enzo-headline level="5" color="blackish">
        {{ 'vehicle.tire-set-x' | translate: { i: index + 1 } }}
        @if (tiresSet.isInstalled) {
          ({{ 'vehicle.tires-mounted' | translate }})
        }
      </enzo-headline>
      <enzo-icon icon="info-outline" color="purple" size="small" (click)="this.showPopover($event)" />
    </div>

    <div class="specs">
      <div>
        <enzo-text color="grey-ultradark">{{ 'vehicle.tire-type' | translate }}</enzo-text>
        <enzo-text>{{ tiresSet.type | i18nTireSetType | translate }}</enzo-text>
      </div>

      <div>
        <enzo-text color="grey-ultradark">{{ 'vehicle.wheel-rim' | translate }}</enzo-text>
        <enzo-text>
          @if (tiresSet.size) {
            {{ tiresSet.size }}''
          }
          {{ tiresSet.rim | i18nTireRimType | translate }}
        </enzo-text>
      </div>
    </div>

    @for (detail of tiresSet.details; let idx = $index; track idx) {
      <div class="details">
        <div class="tire" [ngClass]="detail | tireConditionClass" [class.no-tire]="tiresSet.type === EVehicleTireSetType.WITHOUT_TIRES">
          @if (tiresSet.rim !== EVehicleRimType.WITHOUT_RIMS) {
            <img ngSrc="/inventory/adv/assets/rim.svg" width="47" height="47" disableOptimizedSrcset [loaderParams]="{ getOriginal: true }" />
          }
          <div class="tire-assessment">
            <enzo-icon
              [icon]="tiresSet.type === EVehicleTireSetType.WITHOUT_TIRES ? 'attention' : iconAssessmentConfig[detail.conditionAssessment]?.icon"
              [color]="tiresSet.type === EVehicleTireSetType.WITHOUT_TIRES ? 'orange' : iconAssessmentConfig[detail.conditionAssessment]?.color" />
          </div>
        </div>
        <enzo-text color="grey-ultradark">{{ idx | i18nTireLocation | translate }}</enzo-text>
      </div>

      <enzo-text class="depth">
        <!-- condition < 0 caters for historical data, when -1 was used to signify null -->
        <!-- also zeros were used as fallback values for null, and in that case, we have a tire assessment of NONE -->
        {{
          detail.conditionAssessment === EVehicleTireConditionAssessment.NONE ||
          detail.condition === null ||
          detail.condition === undefined ||
          detail.condition < 0
            ? '-'
            : '' + detail.condition + ' mm'
        }}
      </enzo-text>

      <div class="damages">
        <enzo-text [icon]="damageDescrition()[index][idx].icon" [iconColor]="damageDescrition()[index][idx].iconColor" iconSize="small" iconSpacing="2">
          {{ damageDescrition()[index][idx].text | translate }}
        </enzo-text>

        @if (tiresSet.rim === EVehicleRimType.WITHOUT_RIMS) {
          <enzo-text icon="warning" iconColor="orange" iconSize="small" iconSpacing="2">{{ tiresSet.rim | i18nTireRimType | translate }}</enzo-text>
        }
        @if (tiresSet.type === EVehicleTireSetType.WITHOUT_TIRES) {
          <enzo-text icon="warning" iconColor="orange" iconSize="small" iconSpacing="2">{{ tiresSet.type | i18nTireSetType | translate }}</enzo-text>
        }
      </div>
    }

    <enzo-text class="profile-depth" color="grey-ultradark">{{ 'vehicle.tire-profile-depth' | translate }}</enzo-text>
    <div class="divider"></div>
    <enzo-text class="condition" color="grey-ultradark">{{ 'general.condition' | translate }}</enzo-text>
    @if (tiresSet.isInstalled && tiresSet.flawDescription) {
      <div class="divider"></div>
      <enzo-text color="grey-ultradark">{{ 'general.general' | translate }}</enzo-text>
      <enzo-text [style.grid-column]="'span 4'">{{ tiresSet.flawDescription }}</enzo-text>
    }
  </div>
}
