import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LOCATIONS_MAP, VEHICLE_DAMAGE_LOCATIONS, VehicleDamageLocation, VehicleDamagesService } from '../vehicle-damages.service';
import { Observable, combineLatest, map, startWith } from 'rxjs';
import { IGalleryImage, ImageGalleryComponent } from '@cos/partials/general-info/image-gallery/image-gallery.component';
import { EVehicleInteriorDamagePart, EVehiclePart, IVehicleDamage } from '@caronsale/cos-vehicle-models';
import { I18nGeneralPipesModule, I18nVehicleDamageTypePipe, I18nVehiclePartPipe } from '@caronsale/frontend-pipes';
import { EnzoComponentsModule } from '@enzo';

export type Tab = {
  location: VehicleDamageLocation;
  images: IGalleryImage[];
  parts: (EVehiclePart | EVehicleInteriorDamagePart)[];
};

interface IVm {
  tabs: Tab[];
  selectedTabIndex: number;
}

@Component({
  selector: 'app-vehicle-damages-gallery',
  standalone: true,
  imports: [CommonModule, TranslateModule, ImageGalleryComponent, I18nGeneralPipesModule, EnzoComponentsModule],
  providers: [I18nVehiclePartPipe, I18nVehicleDamageTypePipe],
  templateUrl: './vehicle-damages-gallery.component.html',
  styleUrls: ['./vehicle-damages-gallery.component.scss'],
})
export class VehicleDamagesGalleryComponent {
  // TODO: refactor into signals where images aren't reprocessed on every tab change
  public vm$: Observable<IVm> = combineLatest({
    vehicleDamages: this.vehicleDamagesService.vehicleDamages$,
    selectedLocation: this.vehicleDamagesService.selectedLocation$,
    onLangChange: this.translateService.onLangChange.pipe(startWith(null)),
  }).pipe(
    map(({ vehicleDamages, selectedLocation }) => {
      const tabs = VEHICLE_DAMAGE_LOCATIONS.map(location => ({
        location,
        images: this.getDamageGalleryImages(vehicleDamages[location]),
        parts: LOCATIONS_MAP[location],
      })).filter(tab => tab.images.length);

      return { tabs, selectedTabIndex: tabs.findIndex(({ location }) => location === selectedLocation) };
    }),
  );

  public constructor(
    private vehicleDamagesService: VehicleDamagesService,
    private translateService: TranslateService,
    private damageTypePipe: I18nVehicleDamageTypePipe,
    public i18nVehiclePartPipe: I18nVehiclePartPipe,
  ) {}

  public handleIndexChange(selectedIndex: number, tabs: Tab[]) {
    this.vehicleDamagesService.setSelectedLocation(tabs[selectedIndex].location);
  }

  private getDamageGalleryImages(damages: IVehicleDamage[]): IGalleryImage[] {
    return damages
      .map(({ urlToImage, urlToImages, types, description }) => {
        // TODO: remove this 'if' logic whenever the CRM sets urlToImages instead of just urlToImage
        if (urlToImages?.length) {
          return urlToImages.map(urlToImage => ({
            url: urlToImage,
            urlRef: urlToImage.split('/upload/')[1],
            overlayText: types.map(damageType => this.translateService.instant(this.damageTypePipe.transform(damageType))),
            imageDescription: description,
          }));
        } else {
          return {
            url: urlToImage,
            urlRef: urlToImage.split('/upload/')[1],
            overlayText: types.map(damageType => this.translateService.instant(this.damageTypePipe.transform(damageType))),
            imageDescription: description,
          };
        }
      })
      .reduce<IGalleryImage[]>((state, images) => {
        // TODO: remove this 'if' logic whenever the CRM sets urlToImages instead of just urlToImage
        if (Array.isArray(images)) {
          return [...state, ...images];
        } else {
          return [...state, images];
        }
      }, []);
  }

  public trackByLocation(_: number, { location }: { location: VehicleDamageLocation }): VehicleDamageLocation {
    return location;
  }
}
