import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, map, switchMap, takeUntil, tap } from 'rxjs';

import { I18nService } from '@cosCoreServices/i18n/i18n.service';
import { CosCoreClient } from '@cosCoreServices/core-client/cos-core-client.service';
import { IAuction } from '@caronsale/cos-models';
import { EVehicleCreationOrigin, EVehicleEquipmentType, IVehicleEquipmentData } from '@caronsale/cos-vehicle-models';
import { EnzoComponentsModule } from '@enzo';
import { ExpandablePanelModule } from '@cos/partials/expandable-panel/expandable-panel.module';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { processEquipmentData, sortEquipmentData } from '@cosUtils/business';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';

const MERCEDES_ORIGINS = [EVehicleCreationOrigin.MERCEDES_AUSTRIA, EVehicleCreationOrigin.MERCEDES_FRANCE];

@Component({
  standalone: true,
  selector: 'app-equipment-info',
  templateUrl: './equipment-info.component.html',
  styleUrls: ['./equipment-info.component.scss'],
  imports: [CommonModule, EnzoComponentsModule, ExpandablePanelModule, TranslateModule, MatLegacyTooltipModule],
})
export class EquipmentInfoComponent implements OnInit, OnDestroy {
  @Input() public auction: IAuction;

  public equipmentGroups: Record<string, { equipments: IVehicleEquipmentData[]; translationKey: string }> = {};
  public otherGroup: IVehicleEquipmentData[] = [];

  public equipmentNotAvailableForDesiredLanguage: boolean;
  public EVehicleEquipmentType = EVehicleEquipmentType;
  public EVehicleCreationOrigin = EVehicleCreationOrigin;

  public containerHeight: number = 0;
  public alternativeLayout: boolean;
  public displayDataDisclaimerText: boolean;

  private unsubscribe$: Subject<void> = new Subject<void>();

  public constructor(
    private i18nService: I18nService,
    private cosClient: CosCoreClient,
  ) {}

  public ngOnInit(): void {
    this.loadEquipmentData();
  }

  private loadEquipmentData(): void {
    this.i18nService.languageChanged$
      .pipe(
        takeUntil(this.unsubscribe$),
        tap(() => {
          this.equipmentNotAvailableForDesiredLanguage = false;
        }),
        switchMap(selectedLanguage =>
          this.cosClient
            .requestWithPrivileges('get', `/secured/auctions/${this.auction.uuid}/vehicle/equipment-data?language=${selectedLanguage}`)
            .pipe<[IVehicleEquipmentData[], string, string]>(map(equipmentData => [equipmentData.equipment, equipmentData.language, selectedLanguage])),
        ),
      )
      .subscribe(([equipmentList, equipmentLanguage, selectedLanguage]) => {
        this.equipmentGroups = {};
        this.otherGroup = [];
        const vehicleLineIndex = equipmentList.findIndex(
          equipment => equipment.description === this.auction.associatedVehicle.linesByLanguage?.[selectedLanguage],
        );
        if (vehicleLineIndex >= 0) {
          equipmentList.splice(vehicleLineIndex, 1);
        }
        processEquipmentData(equipmentList, this.equipmentGroups, this.otherGroup);
        sortEquipmentData(this.equipmentGroups, this.otherGroup);
        this.calculateContainerHeight();
        if (equipmentLanguage !== selectedLanguage) {
          this.equipmentNotAvailableForDesiredLanguage = true;
        }
        this.alternativeLayout = MERCEDES_ORIGINS.includes(this.auction.associatedVehicle.origin);
        this.displayDataDisclaimerText = !MERCEDES_ORIGINS.includes(this.auction.associatedVehicle.origin);
      });
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
  }

  public asIsOrder() {
    return 0;
  }

  private calculateContainerHeight(): void {
    // Heights and gaps (in pixels) used for calculating the container height.
    // If you change the CSS or template headline size, or other related properties,
    // make sure to update these values accordingly.
    const titleHeight = 26; // height of the title
    const gapHeight = 24; // gap between title and equipment items
    const equipmentHeight = 32; // height per equipment item
    const paddingHeight = 48; // total padding height
    const cardGap = 32; // gap between cards

    const numGroups = Object.keys(this.equipmentGroups).length;
    const numOtherGroupItems = this.otherGroup.length;

    if (numGroups === 0 && numOtherGroupItems === 0) {
      return;
    }

    const columnHeights: number[] = [0, 0];
    const allGroups = [...Object.values(this.equipmentGroups), { equipments: this.otherGroup }];

    allGroups.forEach((group, index) => {
      const numEquipments = group.equipments.length;
      if (numEquipments === 0) {
        return;
      }
      const cardHeight = titleHeight + gapHeight + numEquipments * equipmentHeight + paddingHeight;
      const columnIndex = index % 2;
      columnHeights[columnIndex] += cardHeight + (columnHeights[columnIndex] > 0 ? cardGap : 0);
    });

    this.containerHeight = Math.max(...columnHeights);
  }
}
