import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GeneralPipesModule } from '@caronsale/frontend-pipes';
import { AddressSelectionModule } from '@cosCoreComponents/address-selection/address-selection.module';
import { UserSelectAddressModalComponent } from './user-select-address-modal.component';
import { CosDropdownModule } from '@cosCoreComponents/cos-common/cos-dropdown/cos-dropdown.module';
import { EnzoComponentsModule } from '@enzo';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    //
    AddressSelectionModule,
    CommonModule,
    CosDropdownModule,
    GeneralPipesModule,
    EnzoComponentsModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
  declarations: [UserSelectAddressModalComponent],
  exports: [UserSelectAddressModalComponent],
})
export class UserSelectAddressModalModule {}
