@if (videoUrls()?.[selectedIndex()]) {
  <div class="selected-video">
    @if (!isPlaying()) {
      <img
        fill
        priority
        [ngSrc]="thumbnailPath()"
        [loaderParams]="{ getVideoThumbnail: true }"
        ngSrcset="640w, 750w, 960w"
        sizes="(max-width: 960px) 100vw, (max-width: 1300px) 55vw, (max-width: 1450px) 50vw, (max-width: 1610px) 45vw, (max-width: 1830px) 40vw, 35vw"
        class="video-thumbnail"
        alt="Video Thumbnail"
        testid="video-thumbnail"
        (click)="isPlaying.set(true)" />
      <button class="play-button" (click)="isPlaying.set(true)" testid="play-button">▶</button>
    } @else {
      <video [src]="videoUrls()[selectedIndex()]" controls [autoplay]="true" testid="vehicle-video"></video>
    }

    @if (videoUrls().length > 1) {
      <enzo-icon color="white" icon="arrow-left" class="arrows" (click)="decreaseSelectedIndex()" />
      <enzo-icon color="white" icon="arrow-right" class="arrows" (click)="increaseSelectedIndex()" />
      <enzo-text class="count-overlay">{{ selectedIndex() + 1 }}/{{ videoUrls().length }}</enzo-text>
    }
  </div>
}
