import { ChangeDetectionStrategy, Component, computed, inject, input } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { map } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';

import { IAuction } from '@caronsale/cos-models';
import { VehiclePipesModule } from '@caronsale/frontend-pipes';
import { EnzoComponentsModule } from '@enzo';
import { EVehicleCreationOrigin } from '@caronsale/cos-vehicle-models';

import { ProductAnalyticsService } from '@cosCoreServices/product-analytics/product-analytics.service';
import { ExpandablePanelModule } from '@cos/partials/expandable-panel/expandable-panel.module';
import { DAMAGE_PARTS } from '@cosCRMComponents/internal-vehicle-condition-info/utils/vehicle-tech-state-utils';

import { PaintStatusInfoModule } from '../paint-status-info/paint-status-info.module';
import { TiresInfoComponent } from '../tires-info/tires-info.component';
import { TechStateInfoComponent } from '../tech-state-info/tech-state-info.component';
import { VehicleDamagesComponent } from '../vehicle-damages/vehicle-damages.component';

@Component({
  standalone: true,
  selector: 'app-vehicle-condition-data',
  templateUrl: './vehicle-condition-data.component.html',
  styleUrls: ['./vehicle-condition-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ExpandablePanelModule,
    EnzoComponentsModule,
    PaintStatusInfoModule,
    TechStateInfoComponent,
    TiresInfoComponent,
    VehicleDamagesComponent,
    VehiclePipesModule,
    TranslateModule,
  ],
})
export class VehicleConditionDataComponent {
  public auction = input.required<IAuction>();
  public hasTechStateDamages = computed(() => this.auction().associatedVehicle.damages.find(damage => DAMAGE_PARTS.includes(damage.location)));
  public privateSellerComplaintTermsUrl = toSignal(
    inject(ProductAnalyticsService)
      .getFeatureFlag('inv-private-seller-complaint-terms', { value: null, payload: { url: null } })
      .pipe(map(flag => flag.payload.url)),
  );
  public vehicleCreationOrigin = EVehicleCreationOrigin;
}
