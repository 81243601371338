import { Component } from '@angular/core';
import { I18nService } from '@cosCoreServices/i18n/i18n.service';
import { Language, getContext } from '@caronsale/enzo';
import { CommonModule } from '@angular/common';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { CountryFlagModule } from '@cos/components/general/country-flag/app-country-flag.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-language-select',
  templateUrl: './language-select.component.html',
  styleUrls: ['./language-select.component.scss'],
  imports: [CommonModule, CountryFlagModule, FormsModule, MatSelectModule, TranslateModule],
  standalone: true,
})
export class LanguageSelectComponent {
  public constructor(public i18nService: I18nService) {}

  public handleOnSelectChange(language: Language) {
    getContext().language = language;
  }
}
