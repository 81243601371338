import { Injectable, signal } from '@angular/core';
import { map, Observable, of, switchMap, tap } from 'rxjs';

import { EAccountAugmentation, IAccount, Validation } from '@caronsale/cos-models';
import { CosCoreClient } from '@cosCoreServices/core-client/cos-core-client.service';
import { CosBuyerClientService } from '@cosCoreServices/cos-salesman-client/cos-buyer-client.service';

@Injectable({
  providedIn: 'root',
})
export class AccountDataService {
  public currentAccount = signal<IAccount>(undefined);

  public constructor(
    private cosClient: CosCoreClient,
    private buyerClientService: CosBuyerClientService,
  ) {}

  public getAccountData(augmentations?: EAccountAugmentation[]): Observable<IAccount> {
    return this.getAccountUuid().pipe(
      switchMap(accountUuid => {
        let requestPath = `/account/data/${accountUuid}`;
        if (augmentations?.length) {
          const encodedAugmentations = encodeURIComponent(augmentations.join(','));
          requestPath += `?augment=${encodedAugmentations}`;
        }
        if (Validation.isUndefinedOrNull(this.currentAccount()) || this.currentAccount().uuid !== accountUuid) {
          return this.cosClient.requestWithPrivileges('get', requestPath).pipe(tap((account: IAccount) => this.currentAccount.set(account)));
        } else {
          return of(this.currentAccount());
        }
      }),
    );
  }

  public updateAccountData(accountDataToUpdate: Partial<IAccount>): Observable<void> {
    return this.getAccountUuid().pipe(
      switchMap(accountUuid => this.cosClient.requestWithPrivileges('put', `/account/data/${accountUuid}`, accountDataToUpdate)),
      tap(() => this.currentAccount.update(currentAccount => ({ ...currentAccount, ...accountDataToUpdate }))),
    );
  }

  public requestBuyerKYCCheckForAccount(): Observable<void> {
    return this.getAccountUuid().pipe(
      switchMap(accountUuid => this.cosClient.requestWithPrivileges('post', `/account/data/${accountUuid}/buyer/kyc-check`, null)),
    );
  }

  private getAccountUuid(): Observable<string> {
    return this.buyerClientService.getCurrentBuyerUser().pipe(map(({ _fk_uuid_account }) => _fk_uuid_account));
  }
}
