import { ChangeDetectionStrategy, Component, computed, effect, input, signal } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';

import { EnzoIconComponent, EnzoTextComponent } from '@enzo';
import { getFilePathFromUrl } from '@cosUtils/general';

@Component({
  standalone: true,
  selector: 'app-video-gallery',
  templateUrl: './video-gallery.component.html',
  styleUrls: ['./video-gallery.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [EnzoIconComponent, EnzoTextComponent, NgOptimizedImage],
})
export class VideoGalleryComponent {
  public videoUrls = input.required<string[]>();
  public isPlaying = signal(false);
  public selectedIndex = signal(0);
  public thumbnailPath = computed(() => getFilePathFromUrl(this.videoUrls()?.[this.selectedIndex()] || ''));

  public constructor() {
    effect(
      () => {
        if (this.videoUrls()?.length) {
          this.selectedIndex.set(0);
        }
      },
      { allowSignalWrites: true },
    );
  }

  public increaseSelectedIndex(): void {
    this.selectedIndex.update(currentIndex => (currentIndex + 1) % this.videoUrls().length);
  }

  public decreaseSelectedIndex(): void {
    this.selectedIndex.update(currentIndex => (currentIndex - 1 + this.videoUrls().length) % this.videoUrls().length);
  }
}
