import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnzoComponentsModule } from '@enzo';
import { AuctionBnplApprovalChanceComponent } from './auction-bnpl-approval-chance.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CosPopoverModule } from '@cos/features/@cos/cos-popover/cos-popover.module';
import { BuyNowPayLaterServiceModule } from '@cosCoreServices/buy-now-pay-later/buy-now-pay-later-service.module';
import { GeneralPipesModule } from '@caronsale/frontend-pipes';
import { TestingModule } from '@cos/components/general/testing/testing.module';

@NgModule({
  declarations: [AuctionBnplApprovalChanceComponent],
  exports: [AuctionBnplApprovalChanceComponent],
  imports: [
    CommonModule,
    EnzoComponentsModule,
    RouterModule,
    TranslateModule,
    CosPopoverModule,
    BuyNowPayLaterServiceModule,
    GeneralPipesModule,
    TestingModule,
  ],
})
export class AuctionBnplApprovalChanceModule {}
