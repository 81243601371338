import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GeneralPipesModule, I18nCurrencyPipe, I18nGeneralPipesModule } from '@caronsale/frontend-pipes';

import { BaseModule } from '@cosCoreComponentsGeneral/base.module';
import { TransportationButtonComponent } from './transportation-button.component';
import { LoadingLayerModule } from '@cosCoreComponentsGeneral/loading-layer/loading-layer.module';
import { UserAddressServiceModule } from '@cosCoreServices/user-address/user-address-service.module';
import { UserSelectAddressModalModule } from '@cosCoreComponents/address-management/user-select-address-modal/user-select-address-modal.module';
import { TestingModule } from '@cosCoreComponentsGeneral/testing/testing.module';
import { EnzoComponentsModule } from '@enzo';

@NgModule({
  imports: [
    BaseModule,
    UserSelectAddressModalModule,
    CommonModule,
    GeneralPipesModule,
    I18nGeneralPipesModule,
    LoadingLayerModule,
    UserAddressServiceModule,
    TestingModule,
    EnzoComponentsModule,
  ],
  declarations: [TransportationButtonComponent],
  exports: [TransportationButtonComponent],
  providers: [I18nCurrencyPipe],
})
export class TransportationButtonModule {}
