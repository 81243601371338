<div
  class="root"
  [ngClass]="{
    'dropdown-visible': dropdownVisible,
    disabled: disabled
  }">
  <div class="prefix" testid="prefix">
    <ng-content />
  </div>

  <div class="wrapper" (blur)="onBlur()" testid="wrapper">
    <div class="button" (click)="toggleDropdown()">
      <span class="label" testid="selected-option-label">
        <img class="icon" *ngIf="selectedOption.iconUrl" [src]="selectedOption.iconUrl" />
        <span>{{ selectedOption.label || (selectedOption.labelTranslationKey | translate: selectedOption.labelTranslationParams) }}</span>
      </span>
      <fa-icon [icon]="dropdownVisible ? 'chevron-up' : 'chevron-down'" testid="dropdown-open-close-button" />
    </div>

    <ul class="dropdown" testid="dropdown">
      <li *ngFor="let option of options" (click)="selectOption(option)" testid="option">
        <img class="icon" *ngIf="option.iconUrl" [src]="option.iconUrl" />
        <span>{{ option.label || (option.labelTranslationKey | translate: option.labelTranslationParams) }}</span>
      </li>
    </ul>
  </div>
</div>
