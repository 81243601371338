<app-expandable-panel [expandedState]="true">
  <div panelTitle class="panel-title">
    <enzo-icon [icon]="'car-inspection'" />
    <enzo-headline level="4" color="blackish">{{ 'general.vehicle-data' | translate }}</enzo-headline>
  </div>

  <div panelContent class="cards-container">
    <div class="card">
      <enzo-headline level="5" color="blackish">{{ 'vehicle.vehicle-details' | translate }}</enzo-headline>
      <div>
        <div class="card-line">
          <enzo-text color="blackish">{{ 'vehicle.make' | translate }}</enzo-text>
          <enzo-text kind="body-medium" align="right">{{ auction.associatedVehicle.make }}</enzo-text>
        </div>
        <div class="card-line">
          <enzo-text color="blackish">{{ 'vehicle.model' | translate }}</enzo-text>
          <enzo-text kind="body-medium" align="right">{{ slicedModel }}</enzo-text>
        </div>
        @if (auction.associatedVehicle.linesByLanguage?.[selectedLanguage()]; as vehicleLine) {
          <div class="card-line">
            <enzo-text color="blackish">Line</enzo-text>
            <enzo-text kind="body-medium" align="right">{{ vehicleLine }}</enzo-text>
          </div>
        }
        <div *ngIf="!isUndefinedOrNull(auction.associatedVehicle.category)" class="card-line">
          <enzo-text color="blackish">{{ 'vehicle.category' | translate }}</enzo-text>
          <enzo-text kind="body-medium" align="right">{{ auction.associatedVehicle.category | i18nVehicleCategory | translate }}</enzo-text>
        </div>
        <div class="card-line">
          <enzo-text color="blackish">{{ 'vehicle.color' | translate }}</enzo-text>
          <enzo-text kind="body-medium" align="right">{{ auction.associatedVehicle.bodyColorCode | i18nVehicleColorType | translate }}</enzo-text>
        </div>
        <div *ngIf="!isUndefinedOrNull(auction.associatedVehicle.doors)" class="card-line">
          <enzo-text color="blackish">{{ 'vehicle.number-of-doors' | translate }}</enzo-text>
          <enzo-text kind="body-medium" align="right">{{ auction.associatedVehicle.doors | i18nVehicleDoors | translate }}</enzo-text>
        </div>
        <div class="card-line">
          <enzo-text color="blackish">{{ 'vehicle.number-of-seats' | translate }}</enzo-text>
          <enzo-text kind="body-medium" align="right">{{ auction.associatedVehicle.numSeats }}</enzo-text>
        </div>

        @if (auction.associatedVehicle.unloadedWeightInKg) {
          <div class="card-line">
            <enzo-text color="blackish">{{ 'transport-task.vehicle.weight' | translate }}</enzo-text>
            <enzo-text kind="body-medium" align="right" testid="weight-in-kg">
              {{ auction.associatedVehicle.unloadedWeightInKg | number: '1.0-0' : lang().lang }} kg
            </enzo-text>
          </div>
        }

        <div class="card-line">
          <enzo-text color="blackish">{{ 'vehicle.upholstery' | translate }}</enzo-text>
          <enzo-text kind="body-medium" align="right">{{ auction.associatedVehicle.upholstery | i18nVehicleUpholstery | translate }}</enzo-text>
        </div>
        <div *ngIf="auction.associatedVehicle.vin" class="card-line">
          <enzo-text color="blackish">{{ 'vehicle.vin-short' | translate }}</enzo-text>
          <enzo-text kind="body-medium" align="right">{{ auction.associatedVehicle.vin }}</enzo-text>
        </div>
        <div *ngIf="auction.associatedVehicle.licensePlate" class="card-line">
          <enzo-text color="blackish">{{ 'vehicle.license-plate' | translate }}</enzo-text>
          <enzo-text kind="body-medium" align="right">{{ auction.associatedVehicle.licensePlate }}</enzo-text>
        </div>
      </div>
    </div>
    <div class="card">
      <enzo-headline level="5" color="blackish">{{ 'vehicle.history' | translate }}</enzo-headline>
      <div class="card-body">
        <div *ngIf="auction.associatedVehicle.ez" class="card-line">
          <enzo-text color="blackish">{{ 'vehicle.ez' | translate }}</enzo-text>
          <enzo-text kind="body-medium" align="right">{{ auction.associatedVehicle.ez }}</enzo-text>
        </div>
        <div *ngIf="auction.associatedVehicle.mileageInKm" class="card-line">
          <enzo-text color="blackish">{{ 'vehicle.mileage-odometer' | translate }}</enzo-text>
          <div class="inline-odometer-text">
            <enzo-text kind="body-medium" align="right"> {{ auction.associatedVehicle.mileageInKm | mileage }} </enzo-text>
            <enzo-icon
              *ngIf="auction.associatedVehicle.isOdometerInMiles"
              icon="warning"
              color="orange"
              size="small"
              [matTooltip]="'vehicle.original-mileage-in-miles' | translate" />
          </div>
        </div>
        <div class="card-line">
          @switch (auction.associatedVehicle.numPreOwners) {
            @case (EUnknownValues.PRE_OWNERS) {
              <enzo-text color="blackish">{{ 'vehicle.number-of-previous-owners-unknown' | translate }}</enzo-text>
              <enzo-text kind="body-medium" align="right">{{ 'general.unknown' | translate }}</enzo-text>
            }
            @case (0) {
              <enzo-text color="blackish">{{ 'vehicle.number-of-previous-owners' | translate }}</enzo-text>
              <enzo-text kind="body-medium" align="right">{{ 'vehicle.new-vehicle' | translate }}</enzo-text>
            }
            @default {
              <enzo-text color="blackish">{{ 'vehicle.number-of-previous-owners' | translate }}</enzo-text>
              <enzo-text kind="body-medium" align="right">{{ auction.associatedVehicle.numPreOwners }}</enzo-text>
            }
          }
        </div>
        <div class="card-line">
          <enzo-text color="blackish">{{ 'vehicle.commercial-usage' | translate }}</enzo-text>
          <enzo-text kind="body-medium" align="right">{{ auction.associatedVehicle.commercialUsage[0] | i18nCommercialUsage | translate }}</enzo-text>
        </div>
        <div *ngIf="!isUndefinedOrNull(auction.associatedVehicle.numKeys)" class="card-line">
          <enzo-text color="blackish">{{ 'vehicle.number-of-keys' | translate }}</enzo-text>
          <enzo-text kind="body-medium" align="right">{{ auction.associatedVehicle.numKeys }}</enzo-text>
        </div>
      </div>
    </div>
    <div class="card">
      <enzo-headline level="5" color="blackish">{{ 'vehicle.engine' | translate }}</enzo-headline>
      <div class="card-body">
        <div *ngIf="!isUndefinedOrNull(auction.associatedVehicle.fuelType)" class="card-line">
          <enzo-text color="blackish">{{ 'vehicle.fuel-type' | translate }}</enzo-text>
          <enzo-text kind="body-medium" align="right">{{ auction.associatedVehicle.fuelType | i18nVehicleFuelType | translate }}</enzo-text>
        </div>
        <div *ngIf="!isUndefinedOrNull(auction.associatedVehicle.enginePowerInHp)" class="card-line">
          <enzo-text color="blackish">{{ 'vehicle.engine-power' | translate }}</enzo-text>
          <enzo-text kind="body-medium" align="right">
            {{ 'unit.power-x-hp' | translate: { value: auction.associatedVehicle.enginePowerInHp } }}
            ({{ 'unit.power-x-kw' | translate: { value: enginePowerInKW } }})
          </enzo-text>
        </div>
        <div
          *ngIf="!isUndefinedOrNull(auction.associatedVehicle.engineSizeInCcm) && auction.associatedVehicle.fuelType !== EFuelType.ELECTRIC"
          class="card-line">
          <enzo-text color="blackish">{{ 'vehicle.engine-size' | translate }}</enzo-text>
          <enzo-text kind="body-medium" align="right">{{ 'unit.volume-x-ccm' | translate: { value: auction.associatedVehicle.engineSizeInCcm } }}</enzo-text>
        </div>
        <div *ngIf="!isUndefinedOrNull(auction.associatedVehicle.transmission)" class="card-line">
          <enzo-text color="blackish">{{ 'vehicle.transmission' | translate }}</enzo-text>
          <enzo-text kind="body-medium" align="right">{{ auction.associatedVehicle.transmission | i18nVehicleTransmission | translate }}</enzo-text>
        </div>
        <div *ngIf="auction.associatedVehicle.fuelType === EFuelType.HYBRID" class="card-line">
          <enzo-text color="blackish">{{ 'vehicle.plugin-hybrid' | translate }}</enzo-text>
          <enzo-text kind="body-medium" align="right">{{ auction.associatedVehicle.isPluginSystem | i18nYesNoUnknown | translate }}</enzo-text>
        </div>
      </div>
    </div>
    <div class="card">
      <enzo-headline level="5" color="blackish">{{ 'vehicle.vehicle-source' | translate }}</enzo-headline>
      <div class="card-body">
        <div class="card-line">
          <enzo-text color="blackish">{{ 'general.vehicle-location' | translate }}</enzo-text>
          <div class="inline-location-text">
            <enzo-text kind="body-medium" align="right">
              <enzo-flag [country]="auction.locationCountryCode" [matTooltip]="auction.locationCountryCode | i18nCountryCode | translate" />
              {{ auction | buyerAuctionLocation }}
              <enzo-icon
                *ngIf="isDutchToForeignAuction"
                icon="info-outline"
                size="small"
                color="purple"
                popoverWidth="300px"
                [popoverText]="'auction.dutch-to-foreign.location-popover' | translate"
                testid="dutch-pickup" />
            </enzo-text>
            <enzo-text *ngIf="auction.hasOwnProperty('distanceToVehicleInKms')" kind="body-medium" align="right">
              , {{ 'auction.x-km-distance' | translate: { distance: auction.distanceToVehicleInKms } }}
            </enzo-text>
          </div>
        </div>
        <div *ngIf="auction.hasOwnProperty('amIRegularBuyer')" class="card-line">
          <enzo-text color="blackish">{{ 'general.seller-source' | translate }}</enzo-text>
          <enzo-text kind="body-medium" align="right">
            @if (
              auction.sellerType === ESellerBusinessType.OEM &&
              (auction.associatedVehicle.origin === EVehicleCreationOrigin.MERCEDES_AUSTRIA ||
                auction.associatedVehicle.origin === EVehicleCreationOrigin.MERCEDES_FRANCE)
            ) {
              Mercedes-Benz
            } @else {
              @if (auction.amIRegularBuyer) {
                {{ auction.sellerAccount.name }}
              } @else {
                {{ auction.sellerType | i18nSellerBusinessType | translate }}
              }
            }
          </enzo-text>
        </div>
        <div class="card-line">
          <enzo-text color="blackish">{{ 'vehicle.is-reimported-vehicle' | translate }}</enzo-text>
          <enzo-text kind="body-medium" align="right">{{ auction.associatedVehicle.isReimportedVehicle | i18nYesNoUnknown | translate }}</enzo-text>
        </div>
        <div class="card-line">
          <enzo-text color="blackish">{{ 'vehicle.country-of-last-registration' | translate }}</enzo-text>
          <enzo-text kind="body-medium" align="right">{{ auction.associatedVehicle.countryOfLastRegistration | i18nCountryCode | translate }}</enzo-text>
        </div>
        <div *ngIf="auction.associatedVehicle.vin" class="card-line">
          <enzo-text color="blackish">{{ 'general.taxation' | translate }}</enzo-text>
          <enzo-text kind="body-medium" align="right">
            <app-taxation-info [taxationInfo]="taxationInfo" />
          </enzo-text>
        </div>
      </div>
    </div>
    <div class="card">
      <enzo-headline level="5" color="blackish">{{ 'vehicle.inspection-details' | translate }}</enzo-headline>
      <div class="card-body">
        <div *ngIf="auction.associatedVehicle.lastHu" class="card-line">
          <enzo-text color="blackish">{{ 'vehicle.general-inspection-valid-to' | translate }}</enzo-text>
          <enzo-text kind="body-medium" align="right">{{ auction.associatedVehicle.lastHu | i18nValueOrUnknown: [unknownValues.DATE] | translate }}</enzo-text>
        </div>
        <div class="card-line">
          <enzo-text color="blackish">{{ 'vehicle.last-service-inspection-date' | translate }}</enzo-text>
          <enzo-text kind="body-medium" align="right">
            @if (showNoServiceNeeded()) {
              {{ 'vehicle.full-service-history-label.not-needed' | translate }}
            } @else {
              {{ auction.associatedVehicle.lastServiceInspectionDate | i18nValueOrUnknown: [unknownValues.DATE] | translate }}
            }
          </enzo-text>
        </div>
        <div class="card-line">
          <enzo-text color="blackish">{{ 'vehicle.last-service-inspection-mileage' | translate }}</enzo-text>
          <enzo-text kind="body-medium" align="right">
            @if (showNoServiceNeeded()) {
              {{ 'vehicle.full-service-history-label.not-needed' | translate }}
            } @else {
              {{ auction.associatedVehicle.lastServiceInspectionMileage | i18nMileageOrUnknown | translate }}
            }
          </enzo-text>
        </div>
        <div class="card-line">
          <enzo-text color="blackish">{{ 'vehicle.full-service-history' | translate }}</enzo-text>
          <enzo-text kind="body-medium" align="right">
            @if (showNoServiceNeeded()) {
              {{ 'vehicle.full-service-history-label.not-needed' | translate }}
            } @else {
              {{ auction.associatedVehicle.fullServiceHistoryType | i18nFullServiceHistory | translate }}
            }
          </enzo-text>
        </div>
      </div>
    </div>
    <div class="card">
      <enzo-headline level="5" color="blackish">{{ 'vehicle.consumption' | translate }}</enzo-headline>
      <div class="card-body">
        <div *ngIf="auction.associatedVehicle.fuelType === EFuelType.ELECTRIC && auction.associatedVehicle.energyConsumption" class="card-line">
          <enzo-text color="blackish">{{ 'vehicle.battery.electrical-consumption-current' | translate }} *</enzo-text>
          <enzo-text kind="body-medium" align="right"> {{ auction.associatedVehicle.energyConsumption }} kWh/100km </enzo-text>
        </div>
        <ng-container *ngIf="auction.associatedVehicle.fuelType !== EFuelType.ELECTRIC">
          <div class="card-line">
            <enzo-text color="blackish">{{ 'vehicle.fuel-consumption' | translate }}</enzo-text>
            <enzo-text kind="body-medium" align="right">
              {{ auction.associatedVehicle.fuelConsumption.wltp | i18nValueWithUnitOrUnknown: 'l/100km' : '1.1-1' | translate }}
              ({{ 'vehicle.test-method.wltp' | translate }}),
              {{ auction.associatedVehicle.fuelConsumption.nedc | i18nValueWithUnitOrUnknown: 'l/100km' : '1.1-1' | translate }}
              ({{ 'vehicle.test-method.nedc' | translate }})
            </enzo-text>
          </div>
          <div class="card-line" class="card-line">
            <enzo-text color="blackish">{{ 'vehicle.co2-emission' | translate }}</enzo-text>
            <enzo-text kind="body-medium" align="right">
              {{ auction.associatedVehicle.co2Emission.wltp | i18nValueWithUnitOrUnknown: 'g/km' | translate }} ({{ 'vehicle.test-method.wltp' | translate }}),
              {{ auction.associatedVehicle.co2Emission.nedc | i18nValueWithUnitOrUnknown: 'g/km' | translate }} ({{ 'vehicle.test-method.nedc' | translate }})
            </enzo-text>
          </div>
          <div *ngIf="auction.associatedVehicle.euroNorm" class="card-line">
            <enzo-text color="blackish">{{ 'vehicle.emission-class' | translate }}</enzo-text>
            <enzo-text kind="body-medium" align="right">{{ auction.associatedVehicle.euroNorm | i18nVehicleEuroNorm | translate }}</enzo-text>
          </div>
        </ng-container>
        <enzo-text *ngIf="consumptionFieldsEmpty" kind="caption">* {{ 'vehicle.no-data-disclaimer' | translate }}</enzo-text>
      </div>
    </div>
    <div class="card" *ngIf="auction.associatedVehicle.fuelType === EFuelType.ELECTRIC || auction.associatedVehicle.fuelType === EFuelType.HYBRID">
      <enzo-headline level="5" color="blackish">{{ 'vehicle.battery.high-voltage-battery' | translate }}</enzo-headline>
      <div class="card-body">
        <div *ngIf="auction.associatedVehicle.batteryRangeInKm" class="card-line">
          <enzo-text color="blackish">{{ 'vehicle.battery.range-km' | translate }}</enzo-text>
          <enzo-text kind="body-medium" align="right">{{ auction.associatedVehicle.batteryRangeInKm }} km</enzo-text>
        </div>
        <div *ngIf="auction.associatedVehicle.batteryCapacityInKwh" class="card-line">
          <enzo-text color="blackish">{{ 'vehicle.battery.capacity-kwh' | translate }}</enzo-text>
          <enzo-text kind="body-medium" align="right">{{ auction.associatedVehicle.batteryCapacityInKwh }} kWh</enzo-text>
        </div>
        <div *ngIf="auction.associatedVehicle.quickChargeInHours" class="card-line">
          <enzo-text color="blackish">{{ 'vehicle.battery.time-to-charge' | translate }} - {{ 'vehicle.battery.quick' | translate }}</enzo-text>
          <enzo-text kind="body-medium" align="right"
            >{{ auction.associatedVehicle.quickChargeInHours }} {{ 'general.time-display.hours-label' | translate }}</enzo-text
          >
        </div>
        <div *ngIf="auction.associatedVehicle.normalChargeInHours" class="card-line">
          <enzo-text color="blackish">{{ 'vehicle.battery.time-to-charge' | translate }} - {{ 'vehicle.battery.normal' | translate }}</enzo-text>
          <enzo-text kind="body-medium" align="right"
            >{{ auction.associatedVehicle.normalChargeInHours }} {{ 'general.time-display.hours-label' | translate }}</enzo-text
          >
        </div>
        <div *ngIf="auction.associatedVehicle.batteryVoltage" class="card-line">
          <enzo-text color="blackish">{{ 'vehicle.battery.voltage' | translate }}</enzo-text>
          <enzo-text kind="body-medium" align="right">{{ auction.associatedVehicle.batteryVoltage }} V</enzo-text>
        </div>
        <div *ngIf="auction.associatedVehicle.hasRentalBattery" class="card-line">
          <enzo-text color="blackish">{{ 'vehicle.battery.rental' | translate }}</enzo-text>
          <div class="rental-battery">
            <enzo-text kind="body-medium" align="right">{{ auction.associatedVehicle.hasRentalBattery | i18nYesNoUnknown | translate }}</enzo-text>
            @if (auction.associatedVehicle.hasRentalBattery) {
              <enzo-icon icon="warning" color="orange" />
            }
          </div>
        </div>
      </div>
      <enzo-text *ngIf="batteryFieldsEmpty" kind="caption">* {{ 'vehicle.no-data-disclaimer' | translate }}</enzo-text>
    </div>
  </div>
</app-expandable-panel>
