<div class="general-info">
  <div class="section">
    <!-- headline -->
    <ngx-skeleton-loader [theme]="{ height: '70px' }" />
    <!-- headline-buttons -->
    <ngx-skeleton-loader count="1" />
    <!-- highlights -->
    <ngx-skeleton-loader count="1" [theme]="{ height: '32px' }" />
    <!-- gallery -->
    <ngx-skeleton-loader [theme]="{ height: '350px' }" />
    <ngx-skeleton-loader count="6" appearance="circle" [theme]="{ width: '83px', height: '60px', 'border-radius': '0' }" />
  </div>
  <div class="section">
    <!-- remaining-time -->
    <ngx-skeleton-loader [theme]="{ height: '70px' }" />
    <!-- bid-info -->
    <ngx-skeleton-loader count="6" />
  </div>
</div>
<!-- auction-details -->
<div class="section auction-details">
  <ngx-skeleton-loader count="4" />
</div>
