import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseModule } from '@cosCoreComponentsGeneral/base.module';
import { IsTransportBookedPipe } from '@caronsale/frontend-pipes';
import { PickupInfoComponent } from '@cosBuyer/partials/pickup-info/pickup-info.component';
import { EnzoComponentsModule } from '@enzo';

@NgModule({
  imports: [CommonModule, BaseModule, EnzoComponentsModule],
  declarations: [PickupInfoComponent],
  exports: [PickupInfoComponent],
  providers: [IsTransportBookedPipe],
})
export class PickupInfoModule {}
