<ng-container *ngIf="vm$ | async as vm" [ngSwitch]="vm.state">
  <enzo-text
    *ngSwitchCase="PriceTargetInfoState.IS_PRIVATE"
    kind="emphasis-label"
    icon="info-outline"
    color="black"
    iconSpacing="2"
    popoverWidth="25%"
    [popoverTemplate]="isPrivatePopoverTemplate"
    testid="price-info">
    {{ 'auction.orientation-price' | translate: { minAsk: vm.auction.minimumRequiredAsk | currencyEuro } }}
  </enzo-text>
  <enzo-text
    *ngSwitchCase="PriceTargetInfoState.RUNNING_REACHED"
    kind="emphasis-label"
    icon="min-ask-price"
    color="green"
    iconSpacing="2"
    popoverWidth="25%"
    [popoverTemplate]="reachedPopoverTemplate"
    testid="price-info">
    {{ 'auction.min-ask-matched' | translate }}
  </enzo-text>
  <enzo-text
    *ngSwitchCase="PriceTargetInfoState.RUNNING_NOT_REACHED_SHOW_MIN_ASK"
    kind="emphasis-label"
    icon="info-outline"
    color="black"
    iconSpacing="2"
    popoverWidth="25%"
    [popoverTemplate]="notReachedPopoverTemplate"
    testid="price-info">
    {{ 'auction.min-ask-matched-at' | translate: { minAsk: vm.auction.minimumRequiredAsk | currencyEuro } }}
  </enzo-text>
  <enzo-text
    *ngSwitchCase="PriceTargetInfoState.RUNNING_ALMOST_REACHED_ONE_BID_LEFT"
    kind="emphasis-label"
    icon="info-outline"
    color="yellow-dark"
    iconSpacing="2"
    popoverWidth="25%"
    [popoverTemplate]="notReachedPopoverTemplate"
    testid="price-info">
    {{ 'auction.min-ask-only-1-bid' | translate }}
  </enzo-text>
  <enzo-text
    *ngSwitchCase="PriceTargetInfoState.RUNNING_ALMOST_REACHED"
    kind="emphasis-label"
    icon="info-outline"
    color="yellow-dark"
    iconSpacing="2"
    popoverWidth="25%"
    [popoverTemplate]="notReachedPopoverTemplate"
    testid="price-info">
    {{ 'auction.min-ask-only-x-more-bids' | translate: { remainingBids: vm.remainingBids } }}
  </enzo-text>
  <enzo-text
    *ngSwitchCase="PriceTargetInfoState.RUNNING_NOT_REACHED"
    kind="emphasis-label"
    icon="info-outline"
    color="black"
    iconSpacing="2"
    popoverWidth="25%"
    [popoverTemplate]="notReachedPopoverTemplate"
    testid="price-info">
    {{ 'auction.min-ask-not-matched-yet' | translate }}
  </enzo-text>
  <enzo-text *ngSwitchCase="PriceTargetInfoState.CLOSED_WON" kind="emphasis-label" icon="check-outline" color="green" iconSpacing="2" testid="price-info">
    {{ 'auction.won-for-x' | translate: { currentBid: vm.currentHighestBidValue | currencyEuro } }}
  </enzo-text>
  <enzo-text
    *ngSwitchCase="PriceTargetInfoState.CLOSED_WON_BELOW_MIN_ASK"
    kind="emphasis-label"
    icon="cancel-outline"
    color="red"
    iconSpacing="2"
    testid="price-info">
    {{ 'auction.won-below-min-ask' | translate }}
    <enzo-text kind="label" color="red"> {{ 'auction.buyer-info.won-below-min-ask-content' | translate }} </enzo-text>
  </enzo-text>
  <enzo-text *ngSwitchCase="PriceTargetInfoState.CLOSED_LOST" kind="emphasis-label" icon="cancel-outline" color="red" iconSpacing="2" testid="price-info">
    {{ 'auction.lost' | translate }}
  </enzo-text>
</ng-container>

<ng-template #notReachedPopoverTemplate>
  <enzo-text kind="emphasis-label"> {{ 'auction.buyer-info.min-ask-not-reached-label' | translate }} </enzo-text>
  <enzo-text kind="label"> {{ 'auction.buyer-info.min-ask-not-reached-content' | translate }} </enzo-text>
</ng-template>

<ng-template #reachedPopoverTemplate>
  <enzo-text kind="emphasis-label"> {{ 'auction.buyer-info.above-min-ask-label' | translate }} </enzo-text>
  <enzo-text kind="label"> {{ 'auction.buyer-info.above-min-ask-content' | translate }} </enzo-text>
</ng-template>

<ng-template #isPrivatePopoverTemplate>
  <enzo-text kind="emphasis-label"> {{ 'auction.buyer-info.private-auction-label' | translate }} </enzo-text>
  <enzo-text kind="label"> {{ 'auction.buyer-info.private-auction-content' | translate }} </enzo-text>
</ng-template>
