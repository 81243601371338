import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { EVehicleInteriorDamagePart, EVehiclePart, IVehicleTechState } from '@caronsale/cos-vehicle-models';
import { VehicleTechStateMap } from '@cosUtils/business';

export type VehicleTechStateFormValue = Pick<IVehicleTechState, 'part' | 'state'> & {
  anomalies: { key: string; value: string[] | string }[];
};

export const TECH_STATE_PARTS_ORDER = [
  EVehiclePart.ENGINE,
  EVehiclePart.TRANSMISSION,
  EVehiclePart.TRANSFER_CASE,
  EVehiclePart.STEERING,
  EVehiclePart.DIFFERENTIAL,
  EVehiclePart.BATTERY,
  EVehiclePart.AIR_CONDITIONER,
  EVehiclePart.BRAKING_SYSTEM,
  EVehiclePart.CHASSIS,
  EVehiclePart.CLUTCH,
];
export const DAMAGE_PARTS: (EVehiclePart | EVehicleInteriorDamagePart)[] = [EVehicleInteriorDamagePart.CENTRAL_LOCK_SYSTEM];

export class VehicleTechStateUtils {
  public static getInitialTechState(techState: IVehicleTechState[]): IVehicleTechState[] {
    const initialTechState: IVehicleTechState[] = techState ? [...techState] : [];

    const wearParts: IVehicleTechState[] = [],
      nonWearParts: IVehicleTechState[] = [];

    // Use Object.values to iterate over the parts
    for (const part of Object.values(VehicleTechStateMap.VEHICLE_PARTS).filter(p => p !== EVehiclePart.OTHER)) {
      const state = VehicleTechStateUtils.getTechStateByPart(initialTechState, part);

      if ([EVehiclePart.BRAKING_SYSTEM, EVehiclePart.CHASSIS, EVehiclePart.CLUTCH].includes(part)) {
        wearParts.push(state);
      } else {
        nonWearParts.push(state);
      }
    }

    return [...nonWearParts, VehicleTechStateUtils.getTechStateByPart(initialTechState, EVehiclePart.OTHER), ...wearParts];
  }

  private static getTechStateByPart(techState: IVehicleTechState[], part: EVehiclePart): IVehicleTechState {
    let state = techState.find(st => st.part === part);
    let anomalies = state?.anomalies;

    if (!state) {
      state = {
        part,
        state: null,
      } as IVehicleTechState;
    }

    if (!anomalies) {
      anomalies = part === EVehiclePart.OTHER ? { other: '' } : {};
    }

    return { ...state, anomalies };
  }

  public static getFormGroup(formBuilder: UntypedFormBuilder, state: IVehicleTechState): UntypedFormGroup {
    let anomalies: UntypedFormGroup[];

    if (state.part !== EVehiclePart.OTHER) {
      anomalies = Object.entries(state.anomalies).map(([key, value]) =>
        formBuilder.group({
          key: [{ value: key, disabled: true }],
          value: [{ value: value, disabled: true }],
        }),
      );
    } else {
      anomalies = [
        formBuilder.group({
          key: ['other'],
          value: [state.anomalies['other']],
        }),
      ];
    }

    return formBuilder.group({
      part: [state.part],
      state: [state.state],
      anomalies: formBuilder.array(anomalies),
    });
  }

  public static getFormNewDraftValue(techStateFormValues: VehicleTechStateFormValue[], techState: IVehicleTechState[]): IVehicleTechState[] {
    return techStateFormValues.map((state, i) => ({
      ...techState[i],
      state: state.state,
      anomalies: state.anomalies.reduce((acc, { key, value }) => ({ ...acc, [key]: value }), {}),
    }));
  }
}
