import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';

import { IAuction, ISellerAuctionView } from '@caronsale/cos-models';
import { EnzoComponentsModule } from '@enzo';
import { I18nGeneralPipesModule, I18nVehiclePipesModule } from '@caronsale/frontend-pipes';
import { ExpandablePanelModule } from '@cos/partials/expandable-panel/expandable-panel.module';
import { Color, Icon } from '@caronsale/enzo';

import { VehicleOriginReportButtonModule } from '../vehicle-origin-report-button/vehicle-origin-report-button.module';
import { VehicleDetailsGridComponent } from '../vehicle-details-grid/vehicle-details-grid.component';
import { CountryCodeService } from '@caronsale/frontend-services';
import { EHuReportAvailable, EVehicleServiceHistoryAvailability } from '@caronsale/cos-vehicle-models';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-document-info',
  standalone: true,
  imports: [
    CommonModule,
    EnzoComponentsModule,
    ExpandablePanelModule,
    I18nVehiclePipesModule,
    I18nGeneralPipesModule,
    TranslateModule,
    VehicleOriginReportButtonModule,
    VehicleDetailsGridComponent,
  ],
  templateUrl: './document-info.component.html',
  styleUrls: ['./document-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentInfoComponent implements OnChanges {
  @Input() public auction: IAuction;
  @Input() public auctionSeller: ISellerAuctionView;

  public inspectionAvailableKey: string;

  public serviceHistoryIconConfig: { type: Icon; color: Color };
  public eHuReportIconConfig: { type: Icon; color: Color };

  public eHuReportAvailable = EHuReportAvailable;
  public eVehicleServiceHistoryAvailability = EVehicleServiceHistoryAvailability;

  public constructor(private countryCodeSvc: CountryCodeService) {}

  public ngOnChanges(): void {
    this.inspectionAvailableKey = this.getInspectionAvailableKey();
    this.updateServiceHistoryIconConfig();
    this.updateHuReportIconConfig();
  }

  private getInspectionAvailableKey(): string {
    return this.countryCodeSvc.userCountryCode === this.auction.associatedVehicle.countryOfLastRegistration
      ? 'vehicle.local-inspection-available'
      : 'vehicle.general-inspection-available';
  }

  private updateServiceHistoryIconConfig(): void {
    const availability = this.auction.associatedVehicle.serviceHistoryAvailability;
    const isPositiveAvailability = availability === EVehicleServiceHistoryAvailability.YES || availability === EVehicleServiceHistoryAvailability.DIGITAL;

    this.serviceHistoryIconConfig = {
      type: isPositiveAvailability ? 'cos-approved' : 'warning',
      color: isPositiveAvailability ? 'green' : 'orange',
    };
  }

  private updateHuReportIconConfig(): void {
    const availability = this.auction.associatedVehicle.huReportAvailable;
    const isPositiveAvailability = availability === EHuReportAvailable.YES || availability === EHuReportAvailable.NEW_CAR;

    this.eHuReportIconConfig = {
      type: isPositiveAvailability ? 'cos-approved' : 'warning',
      color: isPositiveAvailability ? 'green' : 'orange',
    };
  }
}
