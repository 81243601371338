import { ChangeDetectionStrategy, Component, booleanAttribute, computed, inject, input } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { finalize } from 'rxjs';

import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { EnzoComponentsModule } from '@enzo';

import { BuyerAuctionService } from '@cosCoreFeatures/auction-detail/common/auction-service/buyer-auction.service';
import { I18nSnackService } from '@cosCoreServices/i18n-snack/i18n-snack.service';
import { IBuyerAuctionView } from '@caronsale/cos-models';
import { ProductAnalyticsService } from '@cosCoreServices/product-analytics/product-analytics.service';
import { WatchlistAddedProperties } from '@cosCoreServices/product-analytics/amplitude/ampli';
import { RecommendationIdService } from '../services/recommendation-id/recommendation-id.service';

@Component({
  standalone: true,
  selector: 'app-watchlist-button',
  templateUrl: './watchlist-button.component.html',
  styleUrls: ['./watchlist-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [EnzoComponentsModule, TranslateModule, MatTooltipModule],
})
export class WatchlistButtonComponent {
  private recommendationIdService = inject(RecommendationIdService, { optional: true });
  private i18nSnackService = inject(I18nSnackService);
  private buyerAuctionService = inject(BuyerAuctionService);
  private productAnalyticsService = inject(ProductAnalyticsService);

  private processing = false;
  private watchlistCountExperiment = toSignal(this.productAnalyticsService.getFeatureFlag('eg-watchlist-count', { value: 'off' }));
  public displayWatchlistCount = computed(() => this.watchlistCountExperiment()?.value === 'treatment');

  public auction = input.required<IBuyerAuctionView>();
  public watchlistLocation = input.required<WatchlistAddedProperties['Watchlist type']>();
  public cockpitSection = input<WatchlistAddedProperties['Auction category']>();
  public bright = input<boolean, boolean>(false, { transform: booleanAttribute });
  public iconSize = input<'small' | 'medium'>('medium');

  public updateWatchlist() {
    if (this.auction().amIHighestBidder) {
      return this.i18nSnackService.open('auction.bidding.highest-bid-can-not-remove-from-watchlist');
    }
    if (this.processing) {
      return;
    }
    this.processing = true;

    if (!this.auction().isParked) {
      this.buyerAuctionService
        .addToWatchlist(this.auction().uuid, this.recommendationIdService?.getRecommendationId())
        .pipe(finalize(() => (this.processing = false)))
        .subscribe(() => {
          this.i18nSnackService.open('auction.bidding.vehicle-parked');
          this.productAnalyticsService.trackEvent('watchlistAdded', {
            'Watchlist type': this.watchlistLocation(),
            'Auction category': this.cockpitSection(),
            'Auction end time': new Date(this.auction().endingTime).toISOString(),
            Timestamp: new Date().toISOString(),
            'Auction uuid': this.auction().uuid,
          });
        });
    } else {
      this.buyerAuctionService
        .removeFromWatchlist(this.auction().uuid, this.recommendationIdService?.getRecommendationId())
        .pipe(finalize(() => (this.processing = false)))
        .subscribe(() => {
          this.i18nSnackService.open('auction.bidding.vehicle-removed-from-parking-place');
        });
    }
  }
}
