import { ChangeDetectionStrategy, Component, effect, input } from '@angular/core';
import { IAuction } from '@caronsale/cos-models';
import { EnzoComponentsModule } from '@enzo';
import { TranslateModule } from '@ngx-translate/core';
import { VehicleDamagesInfoComponent } from './vehicle-damages-info/vehicle-damages-info.component';
import { VehicleDamagesService } from './vehicle-damages.service';

@Component({
  selector: 'app-vehicle-damages',
  standalone: true,
  imports: [EnzoComponentsModule, TranslateModule, VehicleDamagesInfoComponent],
  providers: [VehicleDamagesService],
  templateUrl: './vehicle-damages.component.html',
  styleUrls: ['./vehicle-damages.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VehicleDamagesComponent {
  public auction = input.required<IAuction>();

  public constructor(private vehicleDamagesService: VehicleDamagesService) {
    effect(() => this.vehicleDamagesService.setAuction(this.auction()));
  }
}
