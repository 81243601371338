import { ChangeDetectionStrategy, Component, input } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { GeneralPipesModule } from '@caronsale/frontend-pipes';

import { EFrequentBuyerStatusTypeNames } from '@cosTypes';
import { EnzoTextComponent } from '@enzo';
import { Color } from '@caronsale/enzo';

export type StatusNameWithinTextConfig = {
  label: string;
  color: Color;
  kind: ReturnType<EnzoTextComponent['kind']>;
  statusName: EFrequentBuyerStatusTypeNames;
};

@Component({
  standalone: true,
  selector: 'app-status-name-within-text',
  template: `<enzo-text
    [kind]="config().kind"
    [color]="config().color"
    [innerHTML]="
      config().label
        | translate
          : {
              statusName:
                '<enzo-text kind=emphasis-label class=' +
                config().statusName +
                '>' +
                ('buyer.loyalty-program.status.' + config().statusName | translate) +
                '</enzo-text>'
            }
        | safeHtml
    " />`,
  styleUrls: ['./status-name-within-text.component.scss'],
  imports: [EnzoTextComponent, TranslateModule, GeneralPipesModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusNameWithinTextComponent {
  public config = input.required<StatusNameWithinTextConfig>();
}
