<enzo-text
  [icon]="auction().isParked ? 'favorite' : 'park-outline'"
  [iconColor]="auction().isParked ? 'yellow' : bright() ? 'black' : 'white'"
  [matTooltip]="(auction().isParked ? 'auction.remove-park-auction' : 'auction.do-park-auction') | translate"
  [color]="bright() ? 'black' : 'white'"
  [iconSize]="iconSize()"
  [class.bright]="bright()"
  [iconSpacing]="displayWatchlistCount() ? '2' : '0'"
  (click)="updateWatchlist()">
  @if (displayWatchlistCount()) {
    {{ auction().timesParked }}
  }
</enzo-text>
