@if (!confirming && !confirmed) {
  <h1>{{ 'self-pickup.title-via-qrcode' | translate }}</h1>
  <p>{{ 'self-pickup.description-via-qrcode' | translate }}</p>

  <enzo-button kind="primary" [label]="'self-pickup.verify-btn' | translate" (enzoClick)="confirmPickup()" />
}

@if (confirming) {
  {{ 'self-pickup.confirming' | translate }}
}

@if (confirmed) {
  <app-pickup-confirmation-success [vehicle]="vehicle" />
}
