import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BaseModule } from '@cosCoreComponentsGeneral/base.module';

import { AuctionDetailViewComponent } from './auction-detail-view.component';
import { LoadingLayerModule } from '@cosCoreComponentsGeneral/loading-layer/loading-layer.module';
import { GeneralInfoModule } from '@cos/partials/general-info/general-info.module';
import { HighestBidInfoModule } from '@cosBuyer/partials/auction-detail-view/highest-bid-info/highest-bid-info.module';
import { InstantPurchaseButtonModule } from '@cosBuyer/partials/auction-detail-view/instant-purchase-button/instant-purchase-button.module';
import { InvoiceDownloadModule } from '@cosBuyer/partials/auction-detail-view/invoice-download/invoice-download.module';
import { HotBidProgressBarModule } from '@cos/partials/status-info/hot-bid-progress-bar/hot-bid-progress-bar.module';
import { TransportationOnlyPopoverModule } from '@cosCoreFeatures/@cos/cos-popover/components/transportation-only-popover/transportation-only-popover.module';
import { PaymentControlModule } from '@cosBuyer/partials/payment-control/payment-control.module';
import { AuctionPipesModule, GeneralPipesModule, UrlThumbnailPipe } from '@caronsale/frontend-pipes';
import { TransportationButtonModule } from '@cosBuyer/partials/transportation-button/transportation-button.module';
import { PickupInfoModule } from '@cosBuyer/partials/pickup-info/pickup-info.module';
import { AuctionDetailSkeletonModule } from '@cos/partials/auction-detail-skeleton/auction-detail-skeleton.module';
import { FinancingActionsModule } from '@cosCoreFeatures/auction-detail/buyer/financing-actions/financing-actions.module';
import { PrebookedServicesModule } from '@cosBuyer/partials/prebooked-services/prebooked-services.module';
import { AuctionTransportationInfoWithModalModule } from '@cosBuyer/partials/auction-detail-view/auction-transportation-info-with-modal/auction-transportation-info-with-modal.module';
import { FeeInfoModule } from '../fee-info/fee-info.module';
import { HighestBidValueModule } from '@cosBuyer/partials/highest-bid-value/highest-bid-value.module';
import { BidInfoFooterModule } from '@cosBuyer/partials/auction-detail-view/bid-info-footer/bid-info-footer.module';
import { VoucherSelectionModule } from '@cosBuyer/partials/voucher-selection/voucher-selection.module';
import { BiddingActionsModule } from './bidding-actions/bidding-actions.module';
import { AuctionBnplApprovalChanceModule } from './auction-bnpl-approval-chance/auction-bnpl-approval-chance.module';
import { LoyaltyBannerComponent } from './loyalty-banner/loyalty-banner.component';

@NgModule({
  imports: [
    AuctionBnplApprovalChanceModule,
    AuctionDetailSkeletonModule,
    AuctionPipesModule,
    AuctionTransportationInfoWithModalModule,
    BaseModule,
    BidInfoFooterModule,
    FeeInfoModule,
    TransportationButtonModule,
    CommonModule,
    FinancingActionsModule,
    GeneralInfoModule,
    GeneralPipesModule,
    HighestBidInfoModule,
    HighestBidValueModule,
    HotBidProgressBarModule,
    InstantPurchaseButtonModule,
    InvoiceDownloadModule,
    LoadingLayerModule,
    PaymentControlModule,
    PickupInfoModule,
    PrebookedServicesModule,
    TransportationOnlyPopoverModule,
    VoucherSelectionModule,
    BiddingActionsModule,
    LoyaltyBannerComponent,
  ],
  declarations: [AuctionDetailViewComponent],
  exports: [AuctionDetailViewComponent],
  providers: [UrlThumbnailPipe],
})
export class AuctionDetailViewModule {}
