<div class="header">
  <fa-stack size="2x">
    <fa-icon icon="circle" stackItemSize="1x" transform="grow-8" />
    <fa-icon [icon]="['far', 'file-alt']" stackItemSize="1x" />
  </fa-stack>
  <h2>{{ 'vehicle.to-vehicle-expose' | translate }}</h2>
</div>

<div class="body">
  <span class="message">{{ 'vehicle.expose-translation.download-ready-message' | translate: { auctionLabel: data.auction.label } }}</span>
  <div class="auction-information full-width">
    <img [src]="data.auction.associatedVehicle.vehicleImages[0].url | urlThumbnail" />
    <div>
      <div class="row">
        <span>{{ 'general.vehicle' | translate }}:</span>
        <span>{{ data.auction.label }}</span>
      </div>
      <div class="row">
        <span>{{ 'vehicle.ez' | translate }}:</span>
        <span>{{ data.auction.associatedVehicle.ez }}</span>
      </div>
      <div class="row">
        <span>{{ 'auction.highest-bid' | translate }}:</span>
        <span>{{ data.auction.currentHighestBidValue | currency: 'EUR' }}</span>
      </div>
    </div>
  </div>
  <button mat-flat-button color="primary" (click)="downloadAndClose()">{{ 'vehicle.to-vehicle-expose' | translate }}</button>
</div>
