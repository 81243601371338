<img mat-card-image class="company-contact-image" [src]="data.url" />

<h3>{{ 'general.your-company-contact' | translate }}</h3>

<h2>{{ data.firstName }} {{ data.lastName }}</h2>

<div class="contact-row">
  <fa-icon icon="phone-square" />

  <div>{{ data.phoneNumber }}</div>
</div>

<div class="contact-row">
  <fa-icon icon="envelope" />

  <a href="mailto:{{ data.mailAddress }}"> {{ data.mailAddress }} </a>
</div>

<div class="contact-row">
  <fa-icon icon="clock" />
  <div>{{ data.businessHours }}</div>
</div>
