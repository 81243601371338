<span class="financing-header">
  <span class="financing-header__label"
    ><span class="financing-header__label__new">{{ 'general.new' | translate }}</span
    >: {{ 'financing.payment-conditions' | translate }}</span
  >
  <span (click)="this.showFinancingInfoPopover($event)" class="financing-header__info-btn"> <fa-icon icon="info-circle" /> </span>
</span>

<div class="financing-actions">
  <mat-form-field class="financing-actions__dropdown" appearance="outline">
    <!-- TODO: Disable selection if credit line is not sufficient -->
    <mat-select [(ngModel)]="this.selectedFinancingOption" (ngModelChange)="this.financingSelectionChanged()">
      <mat-option *ngFor="let option of this.financingOptions" [value]="option">
        {{ 'financing.payment-due-in-x-days' | translate: { days: option.paymentDueInDays } }} (+{{ option.netPrice | currency: 'EUR' }})
      </mat-option>
    </mat-select>
    <!-- TODO: Needs to be translated -->
    <mat-hint> {{ this.currentFinancingLimit | currency: 'EUR' }} credit line available</mat-hint>
  </mat-form-field>
</div>
