<div class="badges-and-bid-value-container">
  <ng-container *ngIf="auction | isAuctionRunning; else auctionClosedState">
    <div class="badges">
      <app-auction-fee-discount-tag [auction]="auction" />
      <app-price-target-info [auction]="auction" />
      <app-cross-border-discount-badge [amount]="auction.buyerCrossBorderProcessingAmount" />
    </div>
    <app-highest-bid-value [auction]="auction" />
  </ng-container>
</div>
<app-auction-vat [auction]="auction" />

<ng-template #auctionClosedState>
  <app-price-target-info [auction]="auction" />
</ng-template>
