<div class="main-layout">
  <div class="toolbar">
    <app-logo />
    <app-language-select />
  </div>

  <div class="content">
    <ng-container *ngIf="!error">
      <app-pickup-confirmation-by-scan *ngIf="uuid" [uuid]="uuid" (confirmationError)="onError($event)" />

      <app-manual-pickup-confirmation *ngIf="!uuid" (confirmationError)="onError($event)" />
    </ng-container>

    <div *ngIf="error" class="errorContainer">
      <app-pickup-confirmation-error [errorCode]="error" />

      <button mat-flat-button color="primary" (click)="resetErrorCode()">{{ 'self-pickup.return' | translate }}</button>
    </div>
  </div>

  <app-footer />
</div>
