import { AfterViewInit, Component, inject, Input, OnChanges, OnDestroy, OnInit, Signal, SimpleChanges, TemplateRef, viewChild } from '@angular/core';
import { BehaviorSubject, combineLatest, map, startWith, Subject, takeUntil } from 'rxjs';
import { toSignal } from '@angular/core/rxjs-interop';

import { TranslateService } from '@ngx-translate/core';
import isEqual from 'lodash.isequal';

import { EAuctionDetailViewSection, ESellerBusinessType, IAuction, IBuyerAuctionView, ISellerAuctionView } from '@caronsale/cos-models';
import { EVehicleCreationOrigin, IVehicle, IVehicleDamage, IVehicleImage } from '@caronsale/cos-vehicle-models';
import { I18nVehicleDamageTypePipe } from '@caronsale/frontend-pipes';
import { I18nService } from '@cosCoreServices/i18n/i18n.service';

import { FileUploadService } from '@cosCoreServices/file-upload/file-upload.service';
import { AdvPreferencesService } from '@cosCoreServices/adv-preferences/adv-preferences.service';
import { IGalleryImage } from '@cos/partials/general-info/image-gallery/image-gallery.component';
import { LookAndFeelService } from '@cosBuyer/partials/services/look-and-feel/look-and-feel.service';
import { ExclusiveBadgeContent } from '@cosBuyer/partials/services/look-and-feel/look-and-feel.service';
import { createGalleryImage, getCloudinaryUploadedFileMimeType, getVehicleDocumentGalleryImages, getVehicleGalleryImages } from './image-gallery/image.utils';
import { BrowserDetectionService } from '@cosCoreServices/browser-detection/browser-detection.service';
import { FEATURE_FLAGS_DEFAULTS, ProductAnalyticsService } from '@cosCoreServices/product-analytics/product-analytics.service';
import { AUCTION_HIGHLIGHTS_CONFIGS, AuctionHighlightConfig } from '@cosUtils/business';

interface IGeneralInfoViewModel {
  auction: IBuyerAuctionView | ISellerAuctionView | IAuction;
  auctionBuyer: IBuyerAuctionView;
  auctionSeller: ISellerAuctionView;
  vehicleGalleryImages: IGalleryImage[];
  damageGalleryImages: IGalleryImage[];
  documentGalleryImages: IGalleryImage[];
  vehicleVideoUrl: string[];
  motorVideoUrl: string[];
  lookAndFeelIcon: string;
  exclusiveBadgeContent: ExclusiveBadgeContent;
  auctionHighlights?: AuctionHighlightConfig[];
}

@Component({
  selector: 'app-general-info',
  templateUrl: './general-info.component.html',
  styleUrls: ['./general-info.component.scss'],
})
export class GeneralInfoComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() public auction: IAuction;
  @Input() public auctionBuyer: IBuyerAuctionView;
  @Input() public auctionSeller: ISellerAuctionView;

  private productAnalyticsService = inject(ProductAnalyticsService);

  public badgeOverlayTemplate = viewChild('badgeOverlayTemplate', { read: TemplateRef<HTMLDivElement> });
  public vehicleConditionTemplate = viewChild<TemplateRef<unknown>>('vehicleCondition');
  public additionalInfo = viewChild<TemplateRef<unknown>>('additionalInfo');
  public specificInfoTemplate = viewChild<TemplateRef<unknown>>('specificInfo');
  public documentInfoTemplate = viewChild<TemplateRef<unknown>>('documentInfo');
  public equipmentInfoTemplate = viewChild<TemplateRef<unknown>>('equipmentInfo');

  public sectionTemplateMapping: { [key in EAuctionDetailViewSection]?: Signal<TemplateRef<unknown>> } = {
    [EAuctionDetailViewSection.VEHICLE_CONDITION]: this.vehicleConditionTemplate,
    [EAuctionDetailViewSection.VEHICLE_ADDITIONAL_INFO]: this.additionalInfo,
    [EAuctionDetailViewSection.VEHICLE_DATA]: this.specificInfoTemplate,
    [EAuctionDetailViewSection.VEHICLE_DOCUMENTS]: this.documentInfoTemplate,
    [EAuctionDetailViewSection.EQUIPMENT]: this.equipmentInfoTemplate,
  };

  public vm$: BehaviorSubject<IGeneralInfoViewModel> = new BehaviorSubject<IGeneralInfoViewModel>(null);

  private unsubscribe$ = new Subject<void>();

  public eVehicleCreationOrigin = EVehicleCreationOrigin;

  public displayReportButton = false;
  public ESellerBusinessType = ESellerBusinessType;

  public displayPremiumSellerBanner = toSignal(
    combineLatest({
      vm: this.vm$,
      premiumSellerFeatureFlag: this.productAnalyticsService.getFeatureFlag('eg-premium-seller-badge', FEATURE_FLAGS_DEFAULTS['eg-premium-seller-badge']),
    }).pipe(map(({ vm, premiumSellerFeatureFlag }) => premiumSellerFeatureFlag.payload.premiumSellersUuids.includes(vm?.auction._fk_uuid_sellerUser))),
  );

  public constructor(
    private i18nService: I18nService,
    private damageTypePipe: I18nVehicleDamageTypePipe,
    private translateService: TranslateService,
    private fileUploadService: FileUploadService,
    public advPreferencesService: AdvPreferencesService,
    public browserDetectionService: BrowserDetectionService,
  ) {}

  public ngOnInit(): void {
    this.i18nService.languageChanged$.pipe(startWith(''), takeUntil(this.unsubscribe$)).subscribe(() => this.refreshViewModel());
  }

  public ngOnChanges(changes: SimpleChanges): void {
    const providedAuction = changes.auction || changes.auctionBuyer || changes.auctionSeller;
    const areThereAuctionChanges = this.areVehiclesDifferent(providedAuction.currentValue.associatedVehicle, providedAuction.previousValue?.associatedVehicle);

    const isAuctionParkedChanged = changes.auctionBuyer?.currentValue.isParked !== changes.auctionBuyer?.previousValue?.isParked;

    if (areThereAuctionChanges || isAuctionParkedChanged) {
      this.refreshViewModel();
      this.displayVehicleOriginButton();
    }
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
  }

  public ngAfterViewInit() {
    this.refreshViewModel();
    this.displayVehicleOriginButton();
  }

  private refreshViewModel(): void {
    const auction: IBuyerAuctionView | ISellerAuctionView | IAuction = this.auctionBuyer || this.auctionSeller || this.auction;
    const damageGalleryImages = this.getDamageGalleryImages(auction.associatedVehicle.damages);
    const documentGalleryImages = getVehicleDocumentGalleryImages(auction.associatedVehicle);
    const lookAndFeelIcon = LookAndFeelService.getAuctionRoomLookAndFeelById(auction.lookAndFeelId)?.partnerIcon;
    const exclusiveBadgeContent = LookAndFeelService.getExclusiveBadgeContent(auction.lookAndFeelId);
    const auctionHighlights = this.getAuctionHighlights(this.auctionBuyer || this.auctionSeller);

    this.vm$.next({
      auction,
      auctionBuyer: this.auctionBuyer,
      auctionSeller: this.auctionSeller,
      vehicleGalleryImages: [
        ...getVehicleGalleryImages(auction.associatedVehicle.vehicleImages, this.badgeOverlayTemplate()),
        ...damageGalleryImages,
        ...documentGalleryImages,
      ],
      damageGalleryImages,
      documentGalleryImages,
      vehicleVideoUrl: this.getVehicleVideos(auction.associatedVehicle.vehicleImages),
      motorVideoUrl: auction.associatedVehicle.urlsToMotorRecordings,
      lookAndFeelIcon,
      exclusiveBadgeContent,
      auctionHighlights,
    });
  }

  private getVehicleVideos(vehicleImages: IVehicleImage[]): string[] {
    const videoMimeTypes = this.fileUploadService.videoMimeTypes.map(mimeType => mimeType.split('/')[1]);
    return vehicleImages
      .filter(image => image.url)
      .filter(image => {
        const imageUrlFileMimeType = getCloudinaryUploadedFileMimeType(image.url);
        return videoMimeTypes.includes(imageUrlFileMimeType);
      })
      .map(image => image.url);
  }

  private getDamageGalleryImages(damages: IVehicleDamage[]): IGalleryImage[] {
    return damages
      .filter(damage => damage.urlToImage)
      .map(damage => {
        // TODO: remove this 'if' logic whenever the CRM sets urlToImages instead of just urlToImage
        if (damage.urlToImages?.length) {
          return damage.urlToImages.map(urlToImage => createGalleryImage(urlToImage, this.createDamageOverlayText(damage)));
        } else {
          return createGalleryImage(damage.urlToImage, this.createDamageOverlayText(damage));
        }
      })
      .reduce<IGalleryImage[]>((state, images) => {
        // TODO: remove this 'if' logic whenever the CRM sets urlToImages instead of just urlToImage
        if (Array.isArray(images)) {
          return [...state, ...images];
        } else {
          return [...state, images];
        }
      }, []);
  }

  private createDamageOverlayText(damage: IVehicleDamage): string[] {
    return damage.types.map(types => this.translateService.instant(this.damageTypePipe.transform(types)));
  }

  // vehicle data may differ in the "updatedAt" date and the order of "technicalState" entries.
  // We have to ignore that to check for equality.
  private areVehiclesDifferent(vehicle1: IVehicle, vehicle2: IVehicle): boolean {
    return !isEqual(this.normalizeVehicle(vehicle1), this.normalizeVehicle(vehicle2));
  }

  private normalizeVehicle(vehicle: IVehicle): IVehicle {
    return {
      ...vehicle,
      updatedAt: null,
      technicalState: vehicle?.technicalState.sort((a, b) => a.part - b.part),
    };
  }

  private displayVehicleOriginButton(): boolean {
    const auction: IBuyerAuctionView | ISellerAuctionView | IAuction = this.auctionBuyer || this.auctionSeller || this.auction;
    if (auction.associatedVehicle.urlToAttachment1 || auction.associatedVehicle.origin === EVehicleCreationOrigin.SELF_REVIEWED) {
      return (this.displayReportButton = true);
    }
    return (this.displayReportButton = false);
  }

  private getAuctionHighlights(auction: IBuyerAuctionView | ISellerAuctionView): AuctionHighlightConfig[] {
    return auction?.highlights?.map(highlight => AUCTION_HIGHLIGHTS_CONFIGS[highlight]) || null;
  }
}
