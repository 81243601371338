<div class="container" [attr.data-alignment]="textAlignment">
  @if (showText) {
    @if (isStartPrice) {
      <enzo-text kind="caption" color="black" testid="start-price-text" align="right">
        {{ 'auction.starting-price' | translate }}
      </enzo-text>
    } @else if (auction.didIBidAtLeastOnce) {
      @if (auction.amIHighestBidder) {
        <enzo-text kind="caption" color="black" testid="highest-bidder-text" align="right">
          {{ 'auction.highest-bidder' | translate }}
        </enzo-text>
      } @else {
        <enzo-text kind="caption" color="black" testid="outbid-text" align="right">
          {{ 'auction.outbid' | translate }}
        </enzo-text>
      }
    }
  }
  <div
    class="bid-value-and-icon"
    [class.animate]="animatePrice"
    [class.clickable]="auction.didIBidAtLeastOnce"
    (click)="auction.didIBidAtLeastOnce && showHighestBidderPopover($event)"
    testid="highest-bid-value"
    [ngClass]="{ 'highest-bidder': auction.didIBidAtLeastOnce && auction.amIHighestBidder, outbid: auction.didIBidAtLeastOnce && !auction.amIHighestBidder }"
    data-cy="bidding_highest-bid-value">
    @if (bidValueSize === 'tiny') {
      <enzo-text kind="body-bold">{{ auction.currentHighestBidValue | currencyEuro }}</enzo-text>
    } @else if (bidValueSize === 'medium') {
      <enzo-headline [level]="5" balance="false" testid="highest-bid-headline">
        {{ auction.currentHighestBidValue | currencyEuro }}
      </enzo-headline>
    } @else {
      @if (auction.didIBidAtLeastOnce && !hideBidStatusIcon) {
        @if (auction.amIHighestBidder) {
          <enzo-icon icon="top-bid" testid="highest-bidder-img" />
        } @else {
          <enzo-icon icon="outbid" testid="outbid-img" />
        }
      }
      <enzo-headline [level]="bidValueSize === 'small' ? '4' : '3'" balance="false" testid="highest-bid-headline">
        {{ auction.currentHighestBidValue | currencyEuro }}
      </enzo-headline>
    }
  </div>
</div>
