import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { privilegesGuard } from '../guards/privileges.guard';
import { USER_PRIVILEGES } from '@caronsale/cos-models';
import { HIDE_MOBILE_FULLSCREEN_OVERLAY } from '@cos/app.component';
import { incompleteSignupGuard } from '@cos/guards/incomplete-signup.guard';

const appRoutes: Routes = [
  {
    path: '',
    canActivate: [incompleteSignupGuard()],
    loadComponent: () => import('@cosCoreComponentsGeneral/login/login.component').then(m => m.LoginComponent),
  },
  {
    path: 'login',
    loadComponent: () => import('@cosCoreComponentsGeneral/login/login.component').then(m => m.LoginComponent),
  },
  {
    path: 'signup',
    data: { [HIDE_MOBILE_FULLSCREEN_OVERLAY]: true },
    loadChildren: () => import('@cosCoreComponentsGeneral/signup/routes'),
  },
  {
    path: 'dealership',
    loadChildren: () => import('@cosCoreFeatures/seller/seller-dashboard.module').then(m => m.SellerDashboardModule),
    canActivate: [privilegesGuard([USER_PRIVILEGES.DEALERSHIP_USER, USER_PRIVILEGES.DEALERSHIP_SUPERVISOR_USER])],
  },
  {
    path: 'pickup',
    data: { [HIDE_MOBILE_FULLSCREEN_OVERLAY]: true },
    canActivate: [incompleteSignupGuard()],
    loadChildren: () => import('@cosCoreFeatures/pickup-confirmation/pickup-confirmation.module').then(m => m.PickupConfirmationModule),
  },
  {
    path: 'salesman',
    loadChildren: () => import('../buyer/buyer-dashboard.module').then(m => m.BuyerDashboardModule),
    canActivate: [incompleteSignupGuard(), privilegesGuard(USER_PRIVILEGES.SALESMAN_USER)],
  },
  {
    path: 'dealership-supervisor',
    loadChildren: () => import('@cosCoreComponentsSeller/supervisor/supervisor-view-container/routes'),
    canActivate: [privilegesGuard(USER_PRIVILEGES.DEALERSHIP_SUPERVISOR_USER)],
  },
  {
    path: '**',
    loadComponent: () => import('@cosCoreComponentsGeneral/not-found/not-found.component').then(m => m.NotFoundComponent),
  },
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(appRoutes, {
      onSameUrlNavigation: 'reload',
      bindToComponentInputs: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppCoreRoutingModule {}
