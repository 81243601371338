import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from '@cosCoreEnvironments/environment';
import { enableElfProdMode } from '@ngneat/elf';
import * as Sentry from '@sentry/angular';
import { Breadcrumb } from '@sentry/angular';

window['SENTRY_CLIENT'] = Sentry.init({
  dsn: 'https://493753add7aef0facf709b8bdb1c3b26@o562579.ingest.us.sentry.io/4508120222138368',
  integrations: [
    Sentry.replayIntegration({
      networkDetailAllowUrls: [/^https:\/\/app\.caronsale\.de\/api/],
      networkCaptureBodies: true,
    }),
  ],
  // integrations: [Sentry.browserTracingIntegration(), Sentry.browserProfilingIntegration()],
  // Tracing
  tracesSampleRate: 0, // 1.0 would capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/app\.caronsale\.de\/api/],
  // Session Replay
  // replaysSessionSampleRate: 0, // 0.1 would set the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  // replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  // Set profilesSampleRate to 1.0 to profile every transaction.
  // Since profilesSampleRate is relative to tracesSampleRate,
  // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
  // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
  // results in 25% of transactions being profiled (0.5*0.5=0.25)
  profilesSampleRate: 0, // 1.0,
  environment: environment.label,
  enabled: !environment.disableSentry,
  ignoreErrors: [/^HTTP 40(3|4 NotFound \/v1\/auction\/salesman)/],
  beforeBreadcrumb(breadcrumb: Breadcrumb, hint) {
    if (breadcrumb.category === 'fetch' || breadcrumb.category === 'xhr') {
      if (breadcrumb.data.url.includes('google-analytics') || breadcrumb.data.url.includes('analytics.google') || breadcrumb.data.url.includes('amplitude')) {
        return null;
      }
      if (breadcrumb.data.url.includes('/api/')) {
        breadcrumb.data = {
          ...breadcrumb.data,
          response_body: hint.xhr?.response,
        };
      }
    }
    return breadcrumb;
  },
});

window.addEventListener('beforeunload', () => {
  if (window['SENTRY_CLIENT']) {
    window['SENTRY_CLIENT'].getOptions().enabled = false;
  }
});

if (environment.production) {
  enableProdMode();
  enableElfProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.log(err));
