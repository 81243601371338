import { ChangeDetectionStrategy, Component, computed, inject, input, Signal } from '@angular/core';
import { NgClass, NgOptimizedImage } from '@angular/common';

import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { EnzoComponentsModule } from '@enzo';
import { Color, Icon } from '@caronsale/enzo';
import { EVehicleRimType, EVehicleTireConditionAssessment, EVehicleTireSetType, IVehicle, IVehicleTireSet } from '@caronsale/cos-vehicle-models';
import { I18nVehicleDamageTypePipe, I18nVehiclePipesModule } from '@caronsale/frontend-pipes';

import { Popover } from '@cosCoreFeatures/@cos/cos-popover/services/popover.service';
import { TiresInfoPopoverComponent } from './tires-info-popover/tires-info-popover.component';
import { I18nTireSetTypePipe } from './pipes/i18n/i18n-tire-set-type.pipe';
import { I18nTireLocationPipe } from './pipes/i18n/i18n-tire-location.pipe';
import { I18nTireRimTypePipe } from './pipes/i18n/i18n-tire-rim-type.pipe';
import { TireConditionClassPipe } from './pipes/tire-condition-class.pipe';
import { VehicleInfoUtils } from '@cosCoreComponents/seller/seller-vehicle-info-edit/utils/vehicle-info-utils';

@Component({
  standalone: true,
  selector: 'app-tires-info',
  templateUrl: './tires-info.component.html',
  styleUrls: ['./tires-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgClass,
    NgOptimizedImage,
    TranslateModule,
    EnzoComponentsModule,
    I18nTireSetTypePipe,
    I18nTireLocationPipe,
    I18nTireRimTypePipe,
    I18nVehiclePipesModule,
    TireConditionClassPipe,
  ],
})
export class TiresInfoComponent {
  private popover = inject(Popover);
  private translateService = inject(TranslateService);
  private i18nVehicleDamageTypePipe = inject(I18nVehicleDamageTypePipe);

  public vehicle = input.required<IVehicle>();
  public tireSets = computed<IVehicleTireSet[]>(() => this.vehicle().tires.slice(0, 3));
  public tireDamages = computed<string[]>(() =>
    VehicleInfoUtils.tireOrder.map(location =>
      this.vehicle()
        .damages.filter(damage => damage.location === location)
        .map(damage => damage.types.map(type => this.translateService.instant(this.i18nVehicleDamageTypePipe.transform(type))).join(', '))
        .join(', '),
    ),
  );
  public damageDescrition: Signal<{ icon: Icon; iconColor: Color; text: string }[][]> = computed(() =>
    this.tireSets().map(tireSet =>
      tireSet.details.map((_, index) => {
        const damageDescrition = tireSet.isInstalled ? this.tireDamages()[index] ?? undefined : tireSet.flawDescription ?? undefined;
        const hasFlaw = tireSet.isInstalled && tireSet.flawDescription;

        return {
          icon: damageDescrition || hasFlaw ? 'warning' : 'check',
          iconColor: damageDescrition || hasFlaw ? 'orange' : 'green',
          text: damageDescrition ? damageDescrition : hasFlaw ? 'general.check-general' : 'vehicle.tires-no-damages',
        };
      }),
    ),
  );

  public EVehicleRimType = EVehicleRimType;
  public EVehicleTireSetType = EVehicleTireSetType;
  public EVehicleTireConditionAssessment = EVehicleTireConditionAssessment;
  public tireOrder = VehicleInfoUtils.tireOrder;
  public iconAssessmentConfig: Record<EVehicleTireConditionAssessment, { icon: Icon; color: Color }> = {
    [EVehicleTireConditionAssessment.BAD]: { icon: 'cancel', color: 'red-highlight' },
    [EVehicleTireConditionAssessment.MEDIUM]: { icon: 'attention', color: 'orange' },
    [EVehicleTireConditionAssessment.GOOD]: { icon: 'check', color: 'green' },
    [EVehicleTireConditionAssessment.NONE]: { icon: 'help', color: 'grey-ultradark' },
  };

  public showPopover(event: Event): void {
    this.popover.open({ content: TiresInfoPopoverComponent, origin: event.currentTarget as HTMLElement });
  }
}
