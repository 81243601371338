<app-expandable-panel [expandedState]="true" testid="panel">
  <div panelTitle class="panel-title">
    <enzo-icon icon="steering-wheel" />
    <enzo-headline level="4" color="blackish">{{ 'general.vehicle-equipment' | translate }}</enzo-headline>
  </div>

  <div panelContent>
    @if (equipmentNotAvailableForDesiredLanguage) {
      <enzo-text kind="caption" icon="info" iconSize="small">{{ 'vehicle.equipment-not-available-for-desired-language' | translate }}</enzo-text>
    }

    <div class="cards-container" [style.height.px]="containerHeight">
      @for (group of equipmentGroups | keyvalue: asIsOrder; track group.value.translationKey) {
        <div class="card">
          <enzo-headline level="5" class="category">{{ group.value.translationKey | translate }}</enzo-headline>

          @for (equipment of group.value.equipments; track equipment.uuid) {
            @if (equipment.type === EVehicleEquipmentType.SPECIAL) {
              <enzo-icon size="small" icon="recommended" color="yellow-dark" [matTooltip]="'general.special-equipment' | translate" />
            } @else {
              <enzo-icon size="small" icon="dot" />
            }
            <enzo-text overflow="ellipsis">{{ equipment.description }}</enzo-text>
          }
        </div>
      }

      @if (otherGroup.length) {
        <div class="card" [class.full-width]="alternativeLayout">
          @if (alternativeLayout) {
            <enzo-headline level="5" class="code">{{ 'general.code' | translate }}</enzo-headline>
            <enzo-headline level="5">{{ 'general.description' | translate }}</enzo-headline>
            <enzo-text kind="emphasis-label" icon="recommended" iconColor="yellow-dark" iconSize="small" iconSpacing="2">
              {{ 'general.special-equipment' | translate }}
            </enzo-text>
          } @else {
            <enzo-headline level="5" class="category">{{ 'general.other-category' | translate }}</enzo-headline>
          }

          @for (equipment of otherGroup; track equipment.uuid) {
            @if (equipment.type === EVehicleEquipmentType.SPECIAL) {
              <enzo-icon size="small" icon="recommended" color="yellow-dark" [matTooltip]="'general.special-equipment' | translate" />
            } @else {
              <enzo-icon size="small" icon="dot" />
            }
            @if (alternativeLayout) {
              <enzo-text kind="body-bold" class="external-id">{{ equipment.externalId }}</enzo-text>
            }
            <enzo-text overflow="ellipsis">{{ equipment.description }}</enzo-text>
          }
        </div>
      }
    </div>

    @if (displayDataDisclaimerText) {
      <enzo-text kind="caption" class="data-disclaimer">* {{ 'vehicle.data-disclaimer-text' | translate }}</enzo-text>
    }
  </div>
</app-expandable-panel>
