import { DatePipe } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { EUserType, ITransportationTask, Validation } from '@caronsale/cos-models';
import { COS_CONSTANTS } from '@cosCoreServices/constants/cos-constants.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { I18nTransportationPipesModule } from '@caronsale/frontend-pipes';

interface IClosedAuctionTransportationInfoData {
  transportationTask: ITransportationTask;
  userType: EUserType;
  transporterCompanyName: string;
  urlToSignedPowerOfAttorneyDocumentFile: string;
}

@Component({
  selector: 'app-transportation-info-modal',
  templateUrl: './transportation-info-modal.component.html',
  styleUrls: ['./transportation-info-modal.component.scss'],
  standalone: true,
  imports: [FontAwesomeModule, TranslateModule, I18nTransportationPipesModule],
  providers: [DatePipe],
})
export class TransportationInfoModalComponent {
  public readonly sellerUser: EUserType = EUserType.DEALERSHIP;
  public readonly buyerUser: EUserType = EUserType.SALESMAN;

  public constructor(
    private datePipe: DatePipe,
    private translateService: TranslateService,
    public constants: COS_CONSTANTS,
    @Inject(MAT_DIALOG_DATA) public data: IClosedAuctionTransportationInfoData,
  ) {}

  public composeDate(date: Date) {
    if (Validation.isUndefinedOrNull(date)) {
      return this.translateService.instant('general.not-known-yet');
    } else {
      return this.datePipe.transform(date, 'dd.MM.yyyy');
    }
  }
}
