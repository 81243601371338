import { booleanAttribute, ChangeDetectionStrategy, Component, computed, inject, input } from '@angular/core';
import { NgTemplateOutlet } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { EPrebookedServiceGuaranteeOptionType, EPrebookedServiceType, IAuction, IPrebookedServiceGuaranteeOption } from '@caronsale/cos-models';
import { EnzoComponentsModule } from '@enzo';
import { COS_CONSTANTS } from '@cosCoreServices/constants/cos-constants.service';
import { I18nVehiclePipesModule, GeneralPipesModule } from '@caronsale/frontend-pipes';
import { EVehiclePart, EVehicleTechState, IVehicleTechState } from '@caronsale/cos-vehicle-models';

import { DAMAGE_PARTS, TECH_STATE_PARTS_ORDER } from '@cosCRMComponents/internal-vehicle-condition-info/utils/vehicle-tech-state-utils';
import { ConfigService } from '@cosCoreServices/config/config.service';
import { Popover } from '@cosCoreFeatures/@cos/cos-popover/services/popover.service';
import { PrebookedServicesUtils } from '@cosUtils/business';
import { ProductAnalyticsService } from '@cosCoreServices/product-analytics/product-analytics.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { VideoGalleryComponent } from '../../video-gallery/video-gallery.component';

const TECH_STATE_ORDER = [...TECH_STATE_PARTS_ORDER, EVehiclePart.OTHER];
const WEAR_PARTS = [EVehiclePart.BRAKING_SYSTEM, EVehiclePart.CHASSIS, EVehiclePart.CLUTCH];

const partInfoListTemplates = {
  [EVehiclePart.ENGINE]:
    '<ul> <li>{crankcase}</li> <li>{crankshaft}</li> <li>{pistons}</li> <li>{connecting-rod}</li> <li>{rod-bearings}</li> <li>{main-bearing}</li> <li>{oil-pump}</li> <li>{timing-chain}</li> <li>{turbocharger}</li> <li>{cylinder-head}</li> </ul>',
  [EVehiclePart.TRANSMISSION]:
    '<ul> <li>{gear-case}</li> <li>{shafts}</li> <li>{gear-pairs}</li> <li>{roll-Lager}</li> <li>{synchronizer-body}</li> <li>{synchronizer-rings}</li> <li>{sliding-pieces}</li> <li>{shift-sleeves}</li> <li>{shift-claws}</li> </ul>',
  [EVehiclePart.TRANSFER_CASE]: '<ul> <li>{all-components}</li> </ul>',
  [EVehiclePart.STEERING]: '<ul> <li>{steering-gear}</li> <li>{hydraulic-pump}</li> <li>{steering-motor}</li> </ul>',
  [EVehiclePart.DIFFERENTIAL]: '<ul> <li>{gears}</li> </ul>',
  [EVehiclePart.BATTERY]: '<ul> <li>{start-battery}</li> </ul>',
  [EVehiclePart.AIR_CONDITIONER]: '<ul> <li>{exceptions}</li> </ul>',
};

@Component({
  standalone: true,
  selector: 'app-tech-state-info',
  templateUrl: './tech-state-info.component.html',
  styleUrl: './tech-state-info.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [EnzoComponentsModule, TranslateModule, I18nVehiclePipesModule, GeneralPipesModule, NgTemplateOutlet, VideoGalleryComponent],
  host: { '[class.single-column]': 'singleColumn()' },
})
export class TechStateInfoComponent {
  private popover = inject(Popover);
  private COS_CONSTANTS = inject(COS_CONSTANTS);
  private configService = inject(ConfigService);
  private translateService = inject(TranslateService);
  private productAnalyticsService = inject(ProductAnalyticsService);

  public auction = input.required<IAuction>();
  public singleColumn = input(false, { transform: booleanAttribute });

  public isGaranteeEnabled = computed(() => this.configService.isGuaranteeEnabled() && this.auction().isCosCheckPlusGuaranteeEnabled);
  public obd2CodesEnabled = toSignal(this.productAnalyticsService.isOn('eg-adv-obd2-codes'));
  private freeGuaranteeForUser = toSignal(this.productAnalyticsService.isOn('inv-complimentary-cos-check-plus-for-high-priced-vehicles'));
  public isFreeOfCharge = computed(
    () =>
      this.freeGuaranteeForUser() &&
      PrebookedServicesUtils.getOption<IPrebookedServiceGuaranteeOption>(
        PrebookedServicesUtils.getService(this.auction().prebookedServices, EPrebookedServiceType.GUARANTEE),
        EPrebookedServiceGuaranteeOptionType.COS_CHECK_PLUS,
      )?.discountPriceNet === 0,
  );

  public hasOtherState = computed(() => this.techStateDisplay().find(techState => techState.part === EVehiclePart.OTHER));
  public coveredTechState = computed(() => this.techStateDisplay().filter(item => item.isCovered));
  public uncoveredTechState = computed(() => this.techStateDisplay().filter(item => !item.isCovered));
  public techStateDisplay = computed(() => {
    return TECH_STATE_ORDER.map(part => {
      const partState = this.auction().associatedVehicle.technicalState.find(state => state.part === part);
      if (!partState || partState.state === EVehicleTechState.NOT_AVAILABLE) return null;

      return {
        part,
        description: partState,
        icon: partState.state === EVehicleTechState.OK ? 'cos-approved' : 'warning',
        iconColor: partState.state === EVehicleTechState.OK ? 'green' : 'orange',
        isCovered: this.isGaranteeEnabled() && !WEAR_PARTS.includes(part) && partState.state === EVehicleTechState.OK,
      };
    }).filter(displayItem => displayItem !== null);
  });
  public damagesDisplay = computed(() => {
    return DAMAGE_PARTS.map(part => {
      const damagesForPart = this.auction().associatedVehicle.damages.filter(damage => damage.location === part);
      if (damagesForPart.length === 0) return null;

      return {
        part,
        damageTypes: damagesForPart.map(damage => damage.types).flat(),
        icon: 'warning',
        iconColor: 'orange',
      };
    }).filter(displayItem => displayItem !== null);
  });

  public EVehiclePart = EVehiclePart;
  public purchasesUrlTemplate = `{prefix} <a target="_blank" href="/salesman/purchases"><u>{link}</u></a> {sufix}`;

  public showLearnMorePopover(event: Event): void {
    const urlLabelLink = this.translateService.instant('auction.guarantee.conditions.message', {
      messageTemplate: `<a target='_blank' href='${this.COS_CONSTANTS.GUARANTEE_CONDITIONS_URL}'><u>{label}</u></a>`,
    });

    this.popover.open({
      width: 300,
      content: this.translateService.instant('auction.guarantee.coverage-info', { urlLabelLink }),
      origin: event.currentTarget as HTMLElement,
    });
  }

  public showGuaranteePopover(event: Event, vehiclePart: IVehicleTechState): void {
    const listInfoKey: string = EVehiclePart[vehiclePart.part].toLowerCase().replace('_', '-');
    const urlLabelLink = this.translateService.instant('auction.guarantee.conditions.message', {
      messageTemplate: `<a target='_blank' href='${this.COS_CONSTANTS.GUARANTEE_CONDITIONS_URL}'><u>{label}</u></a>`,
    });
    const listContent = this.translateService.instant(`auction.guarantee.part-list.${listInfoKey}.message`, {
      messageTemplate: partInfoListTemplates[vehiclePart.part],
    });

    this.popover.open({
      width: 300,
      content: this.translateService.instant('auction.guarantee.part-list.header', { urlLabelLink, listContent }),
      origin: event.currentTarget as HTMLElement,
      customClasses: ['check-bullets'],
    });
  }
}
