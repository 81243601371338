<div class="headline-container">
  <enzo-headline level="6">{{ 'complaint.valid-or-invalid-claim' | translate }}</enzo-headline>
  <enzo-text kind="label" [innerHTML]="'complaint.valid-or-invalid-claim-description.message' | translate: { messageTemplate: description }" />
</div>

@for (status of params.claimStatuses; track status.images) {
  <div class="modal-content">
    <div class="claim-container" [ngClass]="{ valid: status.isValid, invalid: !status.isValid }">
      <div class="image-group">
        @for (img of status.images; track img.src) {
          <img [ngSrc]="img.src" class="vehicle-image" width="123" height="93" />
        }
      </div>
      <enzo-text kind="body-medium" [color]="status.textColor" [icon]="status.icon">{{ status.text | translate }}</enzo-text>
    </div>
    <ul>
      @for (description of status.description; track description) {
        <li>
          <enzo-text kind="label">{{ description | translate }}</enzo-text>
        </li>
      }
    </ul>
  </div>
}
